/**
 * @copyright Elmelo Ltd.
 */
import React from 'react'

import {
    Container,
    Form,
    Button,
    Alert,
    Spinner
} from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import color from '../_common/colors.json'
import './css/auth.css'
import { navigate } from "@reach/router"
import {Core, DDB , Lambda} from '../../api'
import {TextInput} from '../_common/component'
import {PickerEx} from "../_common/picker";
import countryCode from './CountryCode'
import ChatIoT from '../../api/chat/chat_iot'

/**
 */
class AuthSignIn extends React.PureComponent
{
    constructor( props )
    {
        super( props )

    }


    /**
     */
    render()
    {

        return (
            <div>
                <SignIn_View {...this.props}/>
            </div>
        )
    }

    /**
     */
    componentDidMount()
    {
    }
}   // class Auth_SignIn
/**
 */
class SignIn_View extends React.PureComponent
{
    constructor( props )
    {
        super( props )

        this.state={
            forgotPassword: false,
            registerView: false,
            username: '',
            password: '',
            signinButton: true,
            cn_code : '+44',
            auth_opt: "email"

        }

        this._iot = null

    }
    /**
     */
    render()
    {
        return (
            <div className="signinContainer">
                <div className="authTitle">EXPLORE YOUR BUSINESS with  <i
                    style={{fontSize: 24, color: color.colors.light, fontWeight: 700}}>HISTREET BUSINESS</i></div>
                <div className="authContainer">
                    {
                        this.state.auth_opt !== "email"
                        ?
                        <Form.Group>
                            <PickerEx
                                items={countryCode}
                                prompt={"Country"}
                                val={this.state.cn_code}
                                bSearch={true}
                                onChange={(val) => this.setState({cn_code: val}) }
                                className="textBoxProfile"
                            />
                            {/*  <div className="mobileCode"> +440</div>*/}
                            <div style={{flex: 1}}>

                                <TextInput
                                    placeholder="Enter phone number"
                                    type="number"
                                    size="lg"
                                    val={this.state.username}
                                    onChange={(val) => this.username(val)}
                                    className="numberHide"
                                />

                            </div>
                        </Form.Group>
                        :
                        <Form.Group>
                            <div style={{flex: 1}}>

                                <TextInput
                                    placeholder="Enter Email"
                                    type="email"
                                    size="lg"
                                    val={this.state.username}
                                    onChange={(val) => this.username(val)}
                                    className="numberHide"
                                />

                            </div>
                        </Form.Group>
                    }

                    <Form.Group>
                        <TextInput
                            placeholder="Enter password"
                            type="password"
                            size="lg"
                            val={this.state.password}
                            onChange={(val) => this.password(val)}
                        />
                    </Form.Group>
                    {
                        this.state.errMsg ?
                            <Alert variant="danger" onClose={() => this.setState({errMsg: ""})} dismissible>
                                {this.state.errMsg}
                            </Alert> : null
                    }
                    <Form.Group>
                        {
                            !this.state.signinButton ?
                                <div style={{
                                    backgroundColor: "#ddd",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    padding: 12,
                                    color: color.colors.primary,
                                }}
                                >
                                    Processing
                                    <span style={{marginLeft: 8}}>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="primary"
                                            />
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="secondary"
                                            />
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="success"
                                            />
                                        </span>
                                </div> :

                                <Button
                                    onClick={() => this.SignIn()}
                                    style={{
                                        background: "linear-gradient(to right, #C94B4B, #4B134F)",
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                    }}
                                    size="lg"
                                    variant="info">Sign In
                                </Button>


                        }

                    </Form.Group>
                    <Form.Group>
                        <div
                            // onClick={() => this.showRegister(true)}
                            className="authLittle">
                            Not a Member yet? <i onClick={() => navigate("signup")}
                                                 variant="light"
                                                 className="highlight"
                                                 style={{color:"#C94B4B"}}>Join
                            us
                            now!</i>
                        </div>
                    </Form.Group>

                    <div
                        //onClick={() => this.showForgotPass(true)}
                        className="authLittle">
                        <i onClick={() => navigate("forgotten",{state: {
                                auth_email: this.state.auth_opt === "email" ? true : false ,
                                username: this.state.username,
                                cn_code: this.state.cn_code}
                            })}
                           variant="light"
                           className="highlight"
                           style={{color:"#C94B4B"}}>Forgot Password?</i>
                    </div>
                </div>
            </div>
        )
    }
    /**
     */
    SignIn = async () =>
    {
        try
        {
            const aws_core = new Core( {cn: this.state.cn_code.replace(/[+]/g, '')} )

            const {username, password} = this.state;

            this.setState({signinButton: false})

            if( !username || !password )
                throw new Error( 'Please enter username and password' )

            const user = await aws_core.SignIn({
                username: this.state.auth_opt === 'email' ? this.state.username :
                    'usr_' + this.state.cn_code.replace(/[+]/g, '') + this.state.username , password});

            if( !user )
            {
                console.warn( 'SignIn_View: SignIn: user is null', )
                this.setState({errMsg: 'Oops, something went wrong', signinButton: true})

                return
            }

            this.props.RdxCore_AuthStatus( "auth" );

            await this.UpdateWithCognito();

            // this.props.Rdx_BetaId( await aws_core.Id() )
            //
            // this.props.Rdx_Pubid_Init()

            // if(!this.props.__chat.elml)
            //     await this.InitChat()

            this.setState({signinButton: true})

            if( this.props.location.state.fromPage )
            {
                navigate(this.props.location.state.fromPage);
            }
            else
            {
                navigate('/home')
            }

            // await this.saveFCMToken();

            return {}
        }
        catch( err )
        {
            console.warn( 'SignIn_View: SignIn: err: ', err )

            this.setState({errMsg:err.message, signinButton: true})

            if( err.message === 'User is not confirmed.' )
            {
                if(this.state.auth_opt === "email")
                    return navigate( '/auth/email/verification', {state: {username: this.state.username, cn_code: this.state.cn_code}} )
                else
                    return navigate( '/auth/verification', {state: {phone_no: this.state.username, cn_code: this.state.cn_code}} )
            }
            else
                return {err}
        }
    }

    /**
     */
    InitChat = async () =>
    {
        try {

            this.props.Rdx_ChatInit_InProgress()

            const p_iot = {
                beta_id: this.props.__core.betaId,
                cfg: this.props.__cfg,
                OnMsg: this.OnMsg,
                OnEvent: this.OnEvent
            }

            this._iot = new ChatIoT(p_iot)

            const resp_init_iot = await this._iot.Init()

            if (resp_init_iot)
            {
                //
            }

            const resp_conn = await this._iot.Connect('app')

            await this._iot.Subscribe('chat', '__all__')

            if (resp_conn)
            {
                //
            }

            this.props.Rdx_Chat(this._iot)

            this.props.Rdx_ChatInit_Complete()

            this.props.Rdx_Chat_totUnRead_Req()

            return {}

        } catch (err) {

            this.props.Rdx_ChatInit_Complete()

            console.warn('AppMenu_Custom : InitChat : err: ', err)

            return Promise.reject(err)
        }
    }   // InitChat

    /**
     */
    OnMsg = async (topic, msg) =>
    {
        try {
            this.props.Rdx_Chat_totUnRead_Req()

            return {}
        } catch (err) {
            console.warn('AppMenu_Custom: OnMsg: err: ', err)

            return {err}
        }
    }	// OnEvent

    // checkFCMToken = async () =>
    // {
    //     let exists = false;
    //
    //     const aws_ddb = new DDB( {} );
    //     const aws_core = new Core( {} );
    //     const beta_id = await aws_core.Id();
    //
    //     const params_q = {
    //             TableName: elml_cfg.ddb.notify
    //         ,   KeyConditionExpression: "#a = :a"
    //         ,   ExpressionAttributeNames: { '#a': 'beta_user_id' }
    //         ,   ExpressionAttributeValues: { ':a': beta_id }
    //     };
    //
    //     const data = await aws_ddb.Query( params_q );
    //
    //     if( data.Items[0] && data.Items[0].histreet )
    //     {
    //         data.Items[0].histreet.values.forEach((item, index) => {
    //
    //             item = JSON.parse(item);
    //
    //             if (item.Token === this.props.__core.appInfo.FCMToken) {
    //                 exists = true;
    //             }
    //         });
    //     }
    //
    //     return exists;
    // }
    //
    // saveFCMToken = async () =>
    // {
    //     const b_exists = await this.checkFCMToken()
    //
    //     if( b_exists )
    //     {
    //         return;
    //     }
    //     else
    //     {
    //         const aws_ddb = new DDB({});
    //
    //         const aws_core = new Core({});
    //         const beta_id = await aws_core.Id();
    //
    //         let phoneNo = this.state.username;
    //         phoneNo = phoneNo.replace('usr_', '+');
    //
    //         const client = await aws_ddb.Client();
    //
    //         const devInfo = await client.createSet([JSON.stringify({
    //             "Token": this.props.__core.appInfo.FCMToken,
    //             "UUID": Utils.DeviceId()
    //         })]);
    //
    //         var upd_params = {
    //             TableName: elml_cfg.ddb.notify,
    //             Key: {
    //                 "beta_user_id": beta_id,
    //                 "phone_no": phoneNo,
    //             },
    //             UpdateExpression: 'ADD #a :a',
    //             ExpressionAttributeNames: {'#a': "histreet"},
    //             ExpressionAttributeValues: {
    //                 ':a': devInfo
    //             },
    //             ReturnValues: 'UPDATED_NEW'
    //         };
    //
    //         await aws_ddb.Upd( upd_params );
    //     }
    // }
    /**
     */
    username(event)
    {
        this.setState({username: event.target.value.replace(/\s+/g, '').replace(/^(0{1,})/, '')})
    }
    /**
     */
    password(event)
    {
        this.setState({password: event.target.value})
    }
    /**
     */
    UpdateWithCognito = async () =>
    {
        try
        {
            // const aws_ddb = new DDB({});
            const aws_lambda = new Lambda()

            let p_lambda = {
                usr: "alpha",
                actType: 'admin',
                act: "update:alpha:id",
                user_id: this.state.username,
                stage: this.props.__cfg.stage
            };

            console.log("Signin: UpdateWithCognito: p_lambda : ", p_lambda);

            return await aws_lambda.Invoke(p_lambda, this.props.__cfg.lambda('alpha'));

            // console.log("Signin: UpdateWithCognito: resp_lambda: ", resp_lambda);

        }
        catch( err )
        {
            this.setState({sLoader: false})
            console.warn("CreateBusiness err:", err)
            return {err: err}
        }
    }   // UserSignup
    /**
     */
    UpdatePay = async (biz_id , new_user_id) =>
    {
        try
        {
            const aws_ddb = new DDB({});

            const p_upd = {
                TableName: this.props.__cfg.db('pay'),
                Key: {
                    biz_id: biz_id
                },
                UpdateExpression: 'set #a = :a )',

                ExpressionAttributeNames: {
                    '#a': 'alpha_user_id'
                },
                ExpressionAttributeValues: {
                    ':a': new_user_id
                },

                ReturnValues: 'ALL_NEW',
            }


            const resp_upd = await aws_ddb.Upd(p_upd);

            return resp_upd

        }
        catch (err)
        {
            return Promise.reject( err )
        }
    } //UpdatePay
    /**
     */
    UpdateUser = async (user , new_user_id) =>
    {
        try {

            const aws_ddb = new DDB({});

            let p_query = {
                TableName: this.props.__cfg.db('user'),
                KeyConditionExpression: '#a = :a And #b = :b',
                ExpressionAttributeNames: {'#a': 'biz_id' ,'#b': 'user_id'},
                ExpressionAttributeValues: {':a': user.biz_id ,':b': user.user_id}
            };

            const resp = await aws_ddb.Query( p_query );

            const tmp_user = resp.Items[0];

            tmp_user.user_id = new_user_id;
            tmp_user.beta_user_id = new_user_id;

            const params_del = {
                TableName: this.props.__cfg.db('user'),
                Key: {biz_id: user.biz_id , user_id: user.user_id}
            };

            const del_resp = await aws_ddb.delete( params_del );

            const params_set = {
                TableName: this.props.__cfg.db('user'),
                Item: {...tmp_user}
            };

            const set_resp = await aws_ddb.Set( params_set );

            const params_del_email = {
                TableName: this.props.__cfg.db('user:inactive'),
                Item: {...user}
            };

            const del_email_resp = await aws_ddb.Del( params_del_email );

            return {msg: "OK"}


        }
        catch (err) {
            return Promise.reject( err )
        }
    } //UpdateUser
}

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( AuthSignIn )


