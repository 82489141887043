/**
 *	@copyright	Elmelo Ltd.
 */

/**
 */
export default ( state = {
		appStage: 'live',
		bNetwork: true,
		bInternet: true,

		betaId: null,

		bizName: null, bizInfo: null,

		bAuth_Quick: false,	// Staff members have PIN numbers for quick access
		bAuth_Quick_Force: false, // Each usage has to be followed by PIN access.
		// quickWindow: 10*60*1000,	// 10 min
		quickList: [    // list of staff members for quick access
                // {
                //     name: '',    // user name.
                //     id: '',      // User id.
                //     t: '',       // access type
                //     pin: '',     // PIN number. Encrypted using device id.
                //     url: ''      // image url
                // }
			],
		selectedPage: 'home',

		/**
		 * Menu related
		 */
		tsMenuLoad_Processing: 0,
		tsMenuLoad: 0,

		menuObj: null,
		authStatus: 'inprogress',   // 'auth' / 'unauth' / 'veri_reqd'

}, action ) =>
{
	switch( action.type )
	{
		case 'core:auth_status':
			return {...state, authStatus: action.payload}

		case 'core:app_stage':
			return {...state, appStage: action.payload};


		case 'core:beta_id':
			return {...state, betaId: action.payload}

		case 'core:b_network':
			return { ...state, bNetwork: action.payload }

		case 'core:b_internet':
			return { ...state, bInternet: action.payload }

		case 'core:app_info':
			return {...state, appInfo: action.payload}

		case 'core:biz_info':
			return {...state, bizInfo: action.payload};

		case 'core:b_auth_quick':
			return {...state, bAuth_Quick: action.payload};

		case 'core:b_auth_quick_force':
			return {...state, bAuth_Quick_Force: action.payload};
		case 'core:selected_page':
			return { ...state, selectedPage: action.payload }
		default:
			return state;
	}	// switch action.type
}



