/**
 *  @copyright  Elmelo Ltd.
 */

import {DDB} from "../../api"
/**
 */
export const RdxCore_AuthStatus = ( auth_status ) =>
{
    return {
        type: 'core:auth_status'
        ,   payload: auth_status
    }
}   // RdxCore_AuthStatus

/**
 */
export const Rdx_IsAvailable_Network = ( b_conn ) =>
{
    return {
            type: 'core:b_network'
        ,   payload: b_conn
        }
}

/**
 */
export const Rdx_IsAvailable_Internet = ( b_conn ) =>
{
    return {
            type: 'core:b_internet'
        ,   payload: b_conn
        }
}

/**
 */
export const Rdx_AppStage = ( app_stage ) =>
{
    return {
        type: 'core:app_stage'
    ,   payload: app_stage
    };
}

export const Rdx_AppInfo = app_info =>
{
    return {
        type: 'core:app_info'
        ,   payload: app_info
    }
}

/**
 */
export const Rdx_BizInfo = ( biz_info ) =>
{
    return {
        type: 'core:biz_info'
        ,   payload: biz_info
    };
}

/**
 */
export const Rdx_Page = ( page ) =>
{
    return {
        type: 'core:page'
        ,   payload: page
    };
}

/**
 */
export const Rdx_IsQuickAccess = ( b_auth_quick ) =>
{
    return {
        type: 'core:b_auth_quick'
    ,   payload: b_auth_quick
    };
}

/**
 */
export const Rdx_IsQuickAccess_Force = ( b_auth_quick_force ) =>
{
    return {
        type: 'core:b_auth_quick_force'
    ,   payload: b_auth_quick_force
    };
}

/**
 */
export const Rdx_SelectPage = ( page_name ) =>
{
    return {
            type: 'core:selected_page',
            payload: page_name,
        }
}

/** order:menu
 */
export const Rdx_Retr_Biz = () =>
{
    return async (dispatch, gs) =>
    {
        try
        {
            const cfg = gs().__cfg
            const aws_ddb = new DDB({});

            const p_get = {
                    TableName: cfg.db('biz', cfg.stage),
                    Key: {'biz_id' : gs().__core.bizInfo.biz_id },
                    ProjectionExpression: ['biz_ot', 'biz_suspend',
                        'images_featured', 'images_gallery','banner_image',
                        'rating_tot', 'rating_cnt',
                        'setup_progress',
                        'biz_currency','biz_country',
                        'biz_delivery','staff_settings',
                        'biz_addr',"biz_contact",
                        'biz_settings','tbl_no'
                    ].join(',')
                }

            // console.log( 'BizInfo: RetrInfo: p_get: ', p_get );

            const data = await aws_ddb.Get(p_get)

            // console.log( 'BizInfo: RetrInfo: resp_get: ', data );

            dispatch( Rdx_BizInfo({...gs().__core.bizInfo , ...data.Item}) )

            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'BizInfo: RetrInfo: err: ', err );

            this.setState( { bizInfo: {}, bLoading:false } )

            return Promise.reject( err );
        }
    }   // return ...

}   // Rdx_OrderMenuInit
/**
 */
export const Rdx_BetaId = beta_id =>
{
    return {
        type: 'core:beta_id',
        payload: beta_id,
    }
}
