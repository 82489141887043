/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    Nav, Navbar,
    Container, Image,
} from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import {navigate, Redirect} from "@reach/router"
import HSB from './images/HSB.png'
/**
 */
class Hdr extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        if(this.props.__core.authStatus !== 'auth')
        {
            return <Redirect to="/auth/signin" noThrow={true}/>

        }
        return (
            <Navbar fixed="top" expand="lg" style={{backgroundColor:"#fff",borderBottom:"2px solid #eee"}}>
                    <Navbar.Brand href='/' >
                        <Image style={{height:40,width:"auto"}} src={HSB}/>
                    </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">

                    <Nav className="mr-auto">
                    <Nav>
                        <Nav.Link href="#" onSelect={ () => navigate('home') } style={{color:this.props.profile ? "#155799" :"#b7b7b7"}}  className="activeNav">
                            Home
                        </Nav.Link>
                    </Nav>
                    </Nav>
                    {
                        this.props.__core.authStatus ?
                            'auth' === this.props.__core.authStatus ?
                                <Nav>
                                    <Nav.Link href="#" onSelect={ () => navigate('chat') } style={{color:this.props.profile ? "#155799" :"#b7b7b7"}}  className="activeNav">
                                        Chat
                                    </Nav.Link>
                                    <Nav.Link href="#" onSelect={ () => navigate('settings') } style={{color:this.props.profile ? "#155799" :"#b7b7b7"}}  className="activeNav">
                                        Settings
                                    </Nav.Link>
                                </Nav>
                                :
                                'veri_reqd' === this.props.__core.authStatus ?
                                    <Nav>
                                        <Nav.Link href='verification'>Complete SignUp</Nav.Link>
                                    </Nav> :
                                    <Nav>
                                        <Nav.Link href="#" onSelect={ () => navigate('/auth/signin') } style={{color:this.props.signin ? "#155799" :"#b7b7b7"}}  className="activeNav">
                                            Sign In
                                        </Nav.Link>
                                    </Nav> :
                            null
                    }
                </Navbar.Collapse>
            </Navbar>
            )
    }

}   // class Hdr

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Hdr )


