/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import {Core} from '../../api/AWS'
import {navigate} from "@reach/router";

/**
 */
class AuthLogIn extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <div>
                <h1>Authenticating ...</h1>
            </div>
            )
    }

    /**
     */
    componentDidMount()
    {
        // this.VerifyAuth()
        //     .then( data => {} )
        //     .catch( data => {} )
    }

    /**
     */
    VerifyAuth = async () =>
    {
        try
        {
            const aws_core = new Core({});
            const current_cred = await aws_core.Credentials();

            if(current_cred.authenticated)
            {
                const current_user = await aws_core.currentuser();

                if(current_user.attributes &&  ( current_user.attributes.email_verified ||  current_user.attributes.phone_number_verified  ) )
                {
                    this.props.RdxCore_AuthStatus( "auth" );
                    // this.props.Rdx_Pubid_Init();
                    this.props.Rdx_BetaId( await aws_core.Id() );

                    return navigate( '/home' )

                }
                else
                {
                    this.props.RdxCore_AuthStatus( "unauth" );

                    if(current_user.attributes.hasOwnProperty('phone_number_verified'))
                        return navigate( '/auth/email/verification' )
                    else
                        return navigate( '/auth/verification' )

                }
            }
            else
            {
                this.props.RdxCore_AuthStatus( "unauth" );
                return navigate("/auth/signin");
            }

            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'Login: VerifyAuthentication: err: ', err )
            this.setState( {err: err} )
        }
    }

}   // class Auth_LogIn

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( AuthLogIn )


