/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import '../../css/_common.css'
import{Form,Image} from 'react-bootstrap'
import Chat_Inst from './inst'
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faBirthdayCake} from '@fortawesome/free-solid-svg-icons'
import ChatIoT from '../../api/chat/chat_iot'
import {Msg_Send, Mark_Read,Msg_List,Channel_UsrList,Channel_List,Channel_Markers,Msg_Last} from '../../api/chat/chat_engine'
import {Core , Lambda,DDB } from "../../api";
import cuid from "cuid";
import {  animateScroll as scroll  } from 'react-scroll'
import {navigate, Redirect} from "@reach/router";
import Hdr from "../_common/hdr";

let innerWidth = window.innerWidth;
/**
 */
class Chat_Home extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )

        this.state = {
                msgList: []
            ,	msgList_LastKey: null
            ,	msgList_Ts: 0
            ,	_ch: null

            ,	bInit: false
            ,	bRefreshing: true
            ,	bLoading: false
            ,	bMore: false

            ,	bizId: null//this.props.bizId
            ,	bizName: null//this.props.bizName

            ,	f_pubId: this.props.pubId

            ,	inviteIds: this.props.inviteIds
            ,	channelUrl: this.props.channelUrl
            // ,	chName:  this.props.__biz.title

            ,	usrList: []
            ,	hasScrolled: false
            ,   msg: ''
            ,   chatView: false
        }


        // const p_iot = {
        //     cfg: this.props.__cfg,
        //     beta_id: this.props.__core.betaId,
        //
        //     OnMsg: this.OnMsg,
        //     OnErr: this.OnErr,
        //     OnEvent: this.OnEvent,
        // }
        //
        // this._chIoT = new ChatIoT( p_iot )
        this._chIoT = null

    }
    /**
     */
    render()
    {
        console.log("this.props.__chat.channelList",this.props)
        if(this.props.__core.bizInfo)
        {
            return (
                <div>
                    <Hdr/>
                    <div style={{marginHorizontal: 16, flex: 1, marginTop: 68}}>
                        {/*<div className="header">
                        <div className="commonFlex">
                            <FontAwesomeIcon icon={faBirthdayCake} className="chatIcon" color="#b7b7b7"/>
                            <div className="chatName">
                                {this.state.chName}
                            </div>
                        </div>
                    </div>*/}
                        {
                            innerWidth > 767 ?
                                <div>
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <div className="chanelList" style={{height: window.innerHeight}}>
                                            {
                                                this.props.__chat.channelList.length
                                                    ?
                                                    this.props.__chat.channelList.map((ch, idx) => (

                                                        <ChatListItem ch={ch}
                                                                      idx={idx}
                                                                      {...this.props}
                                                                      biz_id={this.props.__core.bizInfo.biz_id}
                                                                      SelectChanel={this.SelectChanel}
                                                        />
                                                    ))
                                                    :
                                                    null

                                            }


                                        </div>
                                        <ChatMsg
                                            {...this.state}
                                            Send={this.Send}
                                            biz_id={this.props.__core.bizInfo.biz_id}
                                            fetchMoreData={this.fetchMoreData}
                                        />
                                    </div>
                                </div> :
                                <div>
                                    {
                                        this.state.chatView ?
                                            <div style={{flex: 1}}>
                                                <ChatMsg
                                                    {...this.state}
                                                    Send={this.Send}
                                                    biz_id={this.props.__core.bizInfo.biz_id}
                                                    fetchMoreData={this.fetchMoreData}
                                                    mobileChat={() => this.setState({chatView: false})}
                                                />
                                                <div style={{height: 60}}>
                                                    <div className="footer">
                                                        <div className="commonFlex">
                                                            <Form.Control
                                                                className="textBox"
                                                                type="text"
                                                                placeholder="Enter Message"
                                                                value={this.state.msg}
                                                                onClick={() => scroll.scrollToBottom()}
                                                                onChange={(val) => this.setState({msg: val.target.value})}
                                                            />
                                                            <div className="sendBtn" onClick={this.Send}>
                                                                Send
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="chanelList" style={{height: window.innerHeight}}>
                                                {
                                                    this.props.__chat.channelList.length
                                                        ?
                                                        this.props.__chat.channelList.map((ch, idx) => (

                                                            <ChatListItem ch={ch}
                                                                          idx={idx}
                                                                          biz_id={this.props.__core.bizInfo.biz_id}
                                                                          SelectChanel={this.SelectChanel}
                                                            />
                                                        ))
                                                        :
                                                        null

                                                }


                                            </div>
                                    }
                                </div>
                        }
                    </div>
                </div>
            )
        }
        else
        {
            return <Redirect to="/settings" noThrow={true}/>

        }
    }
    /**
     */
    SelectChanel = async ( ch ) =>
    {
        try
        {
            this.setState({_ch: ch, msgList: [],bInit:false,chatView:innerWidth > 767 ? false : true  });

            this.props.Rdx_Chat_Channel_List_Update( {_nUnRead: 0 , msg_obj: ch._last} )
            this.props.Rdx_Set_Chat_Channel( ch )


            if(this._chIoT)
            {
                await this._chIoT.Disconnect()
                this._chIoT = null
            }
            const p_iot = {
                cfg: this.props.__cfg,
                beta_id: this.props.__core.betaId,

                OnMsg: this.OnMsg,
                OnErr: this.OnErr,
                OnEvent: this.OnEvent,
            }

            this._chIoT = new ChatIoT( p_iot )

            this._chIoT.Connect( ['inst', ch.ch_id].join(':') )
                    .then( data => {
                        this._chIoT.Subscribe( 'chat', ch.ch_id )
                            .then( data => {} )
                            .catch( err => {} )
                    })
                    .catch( err => {} )

            // this.UsrList(ch)
            //     .then( data => {} )
            //     .catch( err => {} )

            this.Msg_List( false, ch )
                .then( data => {} )
                .catch( err => {})

            this.Mark_Read(ch)
                .then( data => {} )
                .catch( err => {} )

            this.setState({bInit:false});

        }
        catch( err )
        {
            console.warn( "chat:home:SelectChanel err:", err )
            return { err }
        }
    }   // Get_LastMsg
    /**
     */
    GetFriendDetails = async (pub_id)=>
    {
        try
        {
            const cfg = this.props.__cfg;
            const aws_ddb = new DDB({});

            let p_query = {
                TableName: cfg.db('pub_info', cfg.stage),
                KeyConditionExpression: '#a = :a',
                ExpressionAttributeNames: {'#a': 'pub_id'},
                ExpressionAttributeValues: {':a': pub_id},
            };
            // console.log("GetFriendDetails: p_query:",p_query);

            const resp = await aws_ddb.Query( p_query );

            // console.log("GetFriendDetails: resp:",resp);

            return resp.Items[0];

        }
        catch (err)
        {
            console.warn(( "Chat_home : GetFriendDetails : err : ", err ))
            return {err}
        }

    }
    /**
     */
    componentDidMount()
    {
        console.log("asd")

        if(!this.props.__core.bizInfo)
            return

        this.ChanelList()
            .then()
            .catch()

        if(this.props.__chat.channelList.length && this.props.__chat.ch )
        {
            this.SelectChanel(this.props.__chat.ch)
                .then()
                .catch()
        }

        // if( this.state._ch )
        // {
        //     this._chIoT.Connect( ['inst', this.state._ch.ch_id].join(':') )
        //         .then( data => {
        //             this._chIoT.Subscribe( 'chat', this.state._ch.ch_id )
        //                 .then( data => {} )
        //                 .catch( err => {} )
        //         })
        //         .catch( err => {} )
        //
        //     this.Msg_List( true )
        //         .then( data => {} )
        //         .catch( err => {})
        //
        //     this.Mark_Read()
        //         .then( data => {} )
        //         .catch( err => {} )
        // }
        // else
        // {
        //     // // console.log("new channel")
        //
        //     this.Init()
        //         .then( data => {
        //             // // console.log('data: ', data)
        //
        //             this._chIoT.Connect(['inst', this.state._ch.ch_id].join(':'))
        //                 .then( data => {
        //                     this._chIoT.Subscribe( 'chat', this.state._ch.ch_id )
        //                         .then( data => {} )
        //                         .catch( err => {} )
        //                 } )
        //                 .catch( err => {} )
        //
        //             this.Msg_List( true )
        //                 .then( data => {} )
        //                 .catch( err => {})
        //
        //             this.Mark_Read()
        //                 .then( data => {} )
        //                 .catch( err => {} )
        //         } )
        //         .catch( err => {} )
        // }


    }	// componentDidMount
    /**
     */
    componentWillUnmount()
    {
        // if( !this._chHandler )
        if( !this._chIoT )
            return

        this._chIoT.Disconnect()
            .then( data => { this._chIoT = null } )
            .catch( err => {} )

        // this._sb.ChannelHandler_Rem( this._sbHandler_Id )

        // this._chHandler = null
        // this._sbHandler_Id = null
    }	// componentWillUnmount
    //
    /**
     */
    Send = async (msg) =>
    {
        try
        {

            if( !this.state._ch )
                return false

            const msg_send = msg.trim()

            if( !msg_send.length )
                return {msg: 'Empty'}

            this.setState( {bSending: true, msg: ''} )

            const msg_obj = {
                msg: msg_send,
                meta: {},
                usr_id: this.props.__core.bizInfo.biz_id,
                dev_id: cuid(),
                l_id: Date.now(),
            }

            const p_msg = {
                ...msg_obj,

                ch_id: this.state._ch.ch_id,
                pub_id_list: Object.keys(this.state._ch.meta),
                dt_create: Date.now(),
                sender: this.props.__core.bizInfo.biz_title ? this.props.__core.bizInfo.biz_title : this.props.__core.bizInfo.biz_name ,
                alpha: {biz_id: this.props.__core.bizInfo.biz_id},
            }

            console.log("p_msg",p_msg)

            this.AddMsg( [p_msg] )

            scroll.scrollToBottom()

            const chat_msg = await Msg_Send( p_msg, this.props.__cfg )

            // // console.log( 'Chat_Inst: Send: chat_msg: ', chat_msg )

            if( chat_msg )
            {
                //
            }

            return true
        }
        catch( err )
        {
            console.warn( 'Chat_Inst: Send: err: ', err )
            return false
        }
    }	// Send
    /**
     */
    AddMsg = ( msg_arr ) =>
    {

        const msg_list = [...msg_arr, ...this.state.msgList]

        msg_list.sort( (a, b) => a.dt_create > b.dt_create ? -1 : a.dt_create < b.dt_create ? 1 : 0 )

        this.setState( {msgList: msg_list.reverse(), msgList_Ts: Date.now()} )

        // this.props.Rdx_Chat_MsgLast( this.state._ch.url, msg_arr[0] )
    }	// AddMsg
    /**
     */
    fetchMoreData = async () =>
    {
        try
        {

            if(this.state.hasScrolled)
            {
                console.log("asd" )
                this.setState({hasScrolled: false})
                await this.Msg_List(true,this.state._ch)
            }
        }
        catch( err )
        {
            this.setState( {bLoading: false} )

            console.warn( 'Chat_Inst: OnLoad: err: ', err )

            return {err: err}
        }
    }	// OnLoad
    /**
     */
    OnRefresh = async () =>
    {
        try
        {
            // const pr_chat = this.props.__chat

            this.setState( {bRefreshing: true} )

            // const msq_q_obj = await pr_chat.sb.GrpChannel_Pre( this.state._ch, this._qMsg, true )

            // const msg_list = msq_q_obj.list
            // this._qMsg = msq_q_obj.qMsg

            const msg_list = []

            this.setState( {msgList: msg_list.reverse(), bRefreshing: false} )
        }
        catch( err )
        {
            this.setState( {bRefreshing: false} )

            console.warn( 'Chat_Inst: OnRefresh: err: ', err )

            return {err: err}
        }
    }	// OnRefresh
    /**
     */
    OnMsg = ( topic, obj ) =>
    {
        console.log( 'Chat_Inst: OnMsg: topic: ', topic )
        console.log( 'Chat_Inst: OnMsg: obj: ', obj )
        console.log( 'Chat_Inst: OnMsg: obj: ', this.state._ch.ch_id )

        // console.log( 'Chat_Inst: OnMsg: this.state.msgList: ', this.props )


        const msg_idx = this.state.msgList.findIndex( x => {
            if( x.msg_id === obj.msg_id )
            {
                // // console.log( 'Chat_Inst: OnMsg: x: true: ', x )
                return true
            }

            if( x.dev_id === obj.dev_id && x.l_id === obj.l_id )
            {
                // // console.log( 'Chat_Inst: OnMsg: x: true: ', x )
                return true
            }

            return false
        })

        if( 0 > msg_idx )
        {
            this.AddMsg( [obj] )
        }
        else
        {
            this.state.msgList[msg_idx] = obj
            this.setState( {msgList_Ts: Date.now()} )
        }

        if(this.state._ch.ch_id === obj.ch_id)
        {
            this.props.Rdx_Chat_Channel_List_Update( {_nUnRead: 0 , msg_obj: obj} )
        }

        this.Mark_Read({ch_id: obj.ch_id})
            .then( data => {} )
            .catch( err => {} )

        this.props.Rdx_Chat_totUnRead_Req()

        scroll.scrollToBottom()


        // this.AddMsg( [msg] )
    }	// OnMsg
    /**
     */
    OnEvent = ( topic, obj ) =>
    {
        // // console.log( 'Chat_Inst: OnEvent: topic: ', topic )
        // // console.log( 'Chat_Inst: OnEvent: obj: ', obj )

        this.props.Rdx_Chat_totUnRead_Req()

        if( 'msg:read' === obj.e )
        {
        }	// msg:read
        else
        {
            //
        }
    }	// OnEvent
    /**
     */
    OnErr = ( err ) =>
    {
        //
    }	// OnErr
    /**
     */
    Mark_Read = async (ch) =>
    {
        try
        {
            if( !ch.cnt )
            {
                return {}
            }

            const p_ch = {
                usr_id: /*this.props.__chat.pub_id*/ this.props.__core.bizInfo.biz_id,
                ch_id:  ch.ch_id,
                alpha: {biz_id:  this.props.__core.bizInfo.biz_id}

            }

            console.log( 'Chat_Inst: Mark_Read: 3',p_ch )

            await Mark_Read( p_ch, this.props.__cfg )
            //
            return {}
        }
        catch( err )
        {
            console.warn( 'Chat_Inst: Mark_Read: err: ', err )

            return Promise.reject( err )
        }
    }	// Mark_Read
    /**
     */
    Msg_List = async ( b_refresh , ch) =>
    {
        try
        {
            // // console.log( "Chat_Inst: Msg_List: this.props: ", this.props )
            // // console.log( "Chat_Inst: Msg_List: this.state: ", this.state )

            const p_list = {
                ch_id: ch.ch_id,
                start_key: !b_refresh ? null : this.state.msgList_LastKey,
                n_msg: 25,
                alpha: {biz_id:  this.props.__core.bizInfo.biz_id}
            }

            const resp_msg_list = await Msg_List( p_list, this.props.__cfg )

            console.log('resp_msg_list',resp_msg_list.last_key)


            // const msg_list = resp_msg_list.resp

            const msg_list = resp_msg_list.resp;

            const usr_msg_list =  b_refresh ? [...this.state.msgList, ...msg_list] : [...msg_list] ;


            this.setState( {
                hasScrolled: resp_msg_list.last_key ? true : false,
                msgList: usr_msg_list.sort( (a, b) => a.dt_create > b.dt_create ? -1 : a.dt_create < b.dt_create ? 1 : 0 ).reverse(),
                msgList_LastKey: resp_msg_list.last_key,
                bInit: true,
                bRefreshing: b_refresh
            })
            if(!b_refresh)
                scroll.scrollToBottom()
            if(b_refresh && resp_msg_list.last_key)
                window.scrollTo(0, 200)

            return {}
        }
        catch( err )
        {
            console.warn( 'Chat_Inst: Init: err: ', err )

            this.setState( {bInit: true, bRefreshing: false} )

            return Promise.reject( err )
        }
    }	// Msg_List
    /**
     */
    UsrList = async (ch) =>
    {
        try
        {
            const p_list = {
                ch_id: ch.ch_id
            }

            // // console.log( "this.state: p_list: ", this.state );
            // // console.log( "Chat_Inst: UsrList: p_list: ", p_list );

            const usr_list = await Channel_UsrList( p_list, this.props.__cfg )

            console.log( "Chat_Inst: UsrList: usr_list: ", usr_list );

            this.setState( {usrList: usr_list} )

            return {}
        }
        catch( err )
        {
            console.warn( "Chat_Inst: UsrList: err: ", err );

            return Promise.reject( err )
        }
    }	// UsrList
    /**
     */
    ChanelList = async ( b_refresh ) =>
    {
        try
        {
            // this.setState({bRefreshing: true});

            const param = {
                usr: 'alpha',
                alpha: {biz_id:  this.props.__core.bizInfo.biz_id},
                limit: 30,
                start_key: b_refresh ? null : this.state.channel_lastkey,

            }

            const channel_list = await Channel_List( param, this.props.__cfg )

            // console.log( "chat_home: Init: channel_list: ", channel_list );

            const pr_arr = channel_list.resp.map( async x => {
                try
                {
                    const ch_id = x.ch_id

                    const pr_arr = [ this.Get_UnRead( ch_id ), this.Get_LastMsg( ch_id ) , this.GetFriendDetails(x.usr_id) ]
                    const pr_resp = await Promise.all( pr_arr )
                    // console.log( "chat_home: Init: pr_resp: ", pr_resp )

                    return {...x, _nUnRead: pr_resp[0], _last: pr_resp[1], _usr_info : pr_resp[2] }
                }
                catch( err )
                {
                    return { err }
                }
            } )

            const pr_resp = await Promise.all( pr_arr )

            // console.log( "chat_home: Init: pr_resp: ", pr_resp );

            const pr_resp_f = pr_resp.filter( x => !x.err )

            const chat_list = b_refresh ? [...pr_resp_f, ...this.props.__chat.channelList] : pr_resp_f ;

            // console.log("chat_list", chat_list);

            this.props.Rdx_Chat_Channel_List(this.Sort_CH(chat_list));

            this.setState( {
                bRefreshing: false,
                channel_lastkey: channel_list.last_key ,
                hasScrolled: channel_list.last_key ? true : false
            });

            const s_key = [this.props.__cfg.stage,this.props.__core.bizInfo.biz_id,  'ch_list'].join( ':' )

            const aws_core = new Core({})
            await aws_core.StorageSet( s_key, chat_list )

            return {}
        }
        catch( err )
        {
            console.warn( 'Chat_Home: Init: err: ', err )

            this.setState( {bRefreshing: false, bMoreChannels: false} )

            return {err: err}
        }
    }   // Init
    /**
     */
    Get_UnRead = async ( ch_id ) =>
    {
        try
        {
            // console.log( "chat_home: Get_UnRead: this.props: ", this.props )

            const pr_core = this.props.__core

            // console.log( "chat_home: Get_UnRead: ch_id: ", ch_id )

            const ch_markers = await Channel_Markers( {ch_id: ch_id}, this.props.__cfg )

            // console.log( "chat_home: Get_UnRead: ch_markers: ", ch_markers )

            const mark_self = ch_markers.ch_usr.find( x => x.usr_id === pr_core.bizInfo.biz_id )

            // console.log( "chat_home: Get_UnRead: mark_self: ", mark_self )

            return mark_self ? mark_self._lag : 0
        }
        catch( err )
        {
            console.warn( "Chat_ListItem: Get_UnRead: err: ", err )

            // return Promise.reject( err )
            return { err }
        }
    }   // Get_UnRead
    /**
     */
    Get_LastMsg = async ( ch_id ) =>
    {
        try
        {
            const resp_msg_last = await Msg_Last( {
                ch_id: ch_id,
                alpha: {biz_id: this.props.__core.bizInfo.biz_id}

            }, this.props.__cfg )

            // console.log( "chat_home: Get_LastMsg: resp_msg_last: ", resp_msg_last )

            // this.setState( {msgLast: resp_msg_last} )

            return resp_msg_last
        }
        catch( err )
        {
            console.warn( "Chat_ListItem: Get_LastMsg: err: ", err )

            // return Promise.reject( err )
            return { err }
        }
    }   // Get_LastMsg
    /**
     */
    Sort_CH=(f_list)=>
    {
        const ret = Array.from(f_list).sort((a, b) => {
            const a_dt_c = (a._last && a._last.dt_create ? a._last.dt_create : a.dt_create) ;
            const b_dt_c = (b._last && b._last.dt_create ? b._last.dt_create : b.dt_create) ;
            return b_dt_c - a_dt_c
        });
        // console.log("Sort_CH",ret)
        return ret

    }
}   // class Chat_Home
/**
 */
class ChatListItem extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )


        this.state = {
            msgLast : '',
            nUnRead: ''
        }
        this.ch_name = ''
        if(this.props.ch)
        {
            const other_pubid = Object.keys(this.props.ch.meta).filter( key => key !== this.props.biz_id )
            this.ch_name = other_pubid ? this.props.ch.meta[other_pubid].name : this.props.ch.ch_name;
        }

    }   // cstr

    /**
     */
    render()
    {

        console.log("ChatListItem", this.props)
        // if( !this.props.ch )
        //     return null
        //
        // if( !this.props.ch.cnt )
        //     return null

        const msg_last = this.props.ch._last
        const dt_obj = msg_last && msg_last.dt_create ? new Date( msg_last.dt_create ) : null
        const usr_img = this.props.ch._usr_info && this.props.ch._usr_info.prof_thumb ? this.props.ch._usr_info.prof_thumb.url : require('../assets/img/placeholder/noCreditCard.png')
        //

        return (
            <div className="chanelBox"
                 key={["ch", this.props.idx].join(':')}
                 onClick={()=>this.props.SelectChanel(this.props.ch)}
            >
                <div>
                    <Image
                        // className="orderCompleteImage"
                        style={{height: 40, width: 40, marginRight: 8}}
                        src={usr_img}
                        rounded
                    />
                </div>
                <div style={{flex: 1}}>
                    <div className="chName">{this.ch_name}</div>
                    <div className="chLast">
                        {msg_last.msg ? msg_last.usr_id === this.props.biz_id ? 'You: '+ msg_last.msg : msg_last.msg : ''}
                        {dt_obj ? dt_obj.toLocaleDateString() : ''}
                    </div>
                </div>
                {
                    this.props.ch._nUnRead
                    ?
                    <div className="chNotiBox">
                        <div className="chNoti">
                            {this.props.ch._nUnRead}
                        </div>
                    </div>
                    :
                    null

                }

            </div>
        )
    }   // class Chat_ListItem

    /**
     */
    componentDidMount()
    {

    }   // componentDidMount()
    /**
     */
    componentWillUnmount()
    {

    }	// componentWillUnmount

}
/**
 */
class ChatMsg extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )

        this.state = {
            msg: ''
        }
        this.ch_name = ''
        this.ch_img = ''


    }   // cstr

    /**
     */
    render()
    {

        if(this.props._ch)
        {
            const other_pubid = Object.keys(this.props._ch.meta).filter(key => key !== this.props.biz_id)
            this.ch_name = other_pubid ? this.props._ch.meta[other_pubid].name : this.props._ch.ch_name;
            this.ch_img = this.props._ch._usr_info && this.props._ch._usr_info.prof_thumb ? this.props._ch._usr_info.prof_thumb.url : '../assets/img/placeholder/noCreditCard.png';
        }
        return (
            <div style={{flex: 1}}>
                {
                    this.props._ch ?
                        <div className="channelMessageHeader">

                            {
                                innerWidth > 767 ? null :
                                    <div
                                        style={{marginRight: 8}}
                                        onClick={this.props.mobileChat}>
                                        back
                                    </div>
                            }

                            <div>
                                <Image
                                    // className="orderCompleteImage"
                                    style={{height: 40, width: 40, marginRight: 8}}
                                    src={this.ch_img}
                                    rounded
                                />
                            </div>
                            <div style={{flex: 1}}>
                                <div className="chName">{this.ch_name}</div>
                            </div>
                        </div> :
                        null
                }

                <div className="messageBody">
                    <Chat_Inst {...this.props}/>
                </div>
                {
                    this.props._ch ?
                        <div className="footer">
                            <div className="commonFlex">
                                <Form.Control
                                    className="textBox"
                                    type="text"
                                    placeholder="Enter Message"
                                    value={this.state.msg}
                                    onClick={() => scroll.scrollToBottom()}
                                    onChange={(val) => this.setState({msg: val.target.value})}
                                />
                                <div className="sendBtn" onClick={() => {
                                    this.props.Send(this.state.msg)
                                    this.setState({msg: ''})
                                }}>
                                    Send
                                </div>
                            </div>
                        </div> :
                        null
                }
            </div>
        )
    }   // class Chat_ListItem

    /**
     */
    componentDidMount()
    {

    }   // componentDidMount()
    /**
     */
    componentWillUnmount()
    {

    }	// componentWillUnmount

}
/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Chat_Home )


