/**
 *	@copyright	Elmelo Ltd.
 */

import elml_cfg from '../../_config/elml_cfg'

export default ( state = {...elml_cfg}, action ) =>
{
    switch( action.type )
    {
        case 'cfg:stage':
            return {...state, stage: action.payload}

        default:
            return state
    }   // switch ...
}   // export default





