/**
 * @copyright Elmelo Ltd.
 */
import React from 'react'

import {
    Container,
    Form,
    Button, Alert
} from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import color from '../_common/colors.json'
import './css/auth.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import { navigate } from "@reach/router"
import {TextInput,Password_SignUp} from '../_common/component'
import {Core} from "../../api";
import {PickerEx} from "../_common/picker";
// import elml_cfg from '../../_config/elml_cfg'
import countryCode from './CountryCode'

/**
 */
class AuthSignUp extends React.PureComponent
{
    constructor( props )
    {
        super( props )
        this.state={
            verification:false,
            username: "",
            password:"",
            errMsg: "",
            loading: false,
            verificationView: false,
            cn_code : '+44',
            auth_opt: "email"

        }
    }
    /**
     */
    render()
    {
        return (
            <div>
                <div className="signinContainer">
                    <div className="authTitle">Join <i
                        style={{fontSize: 24, color: color.colors.light, fontWeight: 700}}>HISTREET</i> Community
                    </div>
                    <div className="authContainer">
                        <div onClick={() => navigate("signin")} className="buttonAuthDiv">
                            <FontAwesomeIcon
                                className="buttonAuth"
                                icon={faTimes}
                                size="lg"
                                color={"red"}/>
                        </div>
                        <Form.Group>
                            <div className="authLittle">
                                This website is engineered by <i>HISTREET</i>. You can access this website and all the
                                other <i>HISTREET</i> powered restaurants with this <i>HISTREET</i> account.
                            </div>
                        </Form.Group>
                        {
                            this.state.auth_opt !== "email"
                            ?
                            <Form.Group>
                                <PickerEx
                                    items={countryCode}
                                    prompt={"Country"}
                                    val={this.state.cn_code}
                                    bSearch={true}
                                    onChange={(val) => this.setState({cn_code: val}) }
                                    className="textBoxProfile"
                                />
                                {/*<div className="mobileCode"> +440</div>*/}
                                <div style={{flex: 1}}>
                                    <TextInput
                                        placeholder="Enter phone number"
                                        type="number"
                                        size="lg"
                                        val={this.state.username}
                                        onChange={(val)=> this.setState({username: val.target.value.replace(/\s+/g, '').replace(/^(0{1,})/, '')}) }
                                        className="numberHide"
                                    />
                                </div>
                            </Form.Group>
                            :
                            <Form.Group>
                                <div style={{flex: 1}}>
                                    <TextInput
                                        placeholder="Enter Email"
                                        type="email"
                                        size="lg"
                                        val={this.state.username}
                                        onChange={(val)=> this.setState({username: val.target.value.replace(/\s+/g, '').replace(/^(0{1,})/, '')}) }
                                        className="numberHide"
                                    />
                                </div>
                            </Form.Group>
                        }


                        <Password_SignUp OnChange={(val) => { this.setState( {password: val} ) } } />
                        {
                            this.state.errMsg?
                                <Alert variant="danger" onClose={() => this.setState({errMsg: ""})} dismissible>
                                    {this.state.errMsg}
                                </Alert> : null
                        }
                        <Form.Group>
                            <Button
                                onClick={this.SignUp}
                                style={{
                                    background: "linear-gradient(to right, #C94B4B, #4B134F)",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%"
                                }}
                                size="lg" variant="info">Register Now
                            </Button>
                        </Form.Group>
                        <Form.Group>
                            <Form.Group>
                                <div className="authLittle">
                                    By clicking Register now, you agree to the
                                    <a href="https://elmelo.com/legal/termsOfServices.html"><i
                                        className="highlight">Terms of Use</i></a> and <a
                                    href="https://elmelo.com/legal/privacyPolicy.html"><i className="highlight">Privacy
                                    Policy</i></a>
                                </div>
                            </Form.Group>
                        </Form.Group>
                    </div>
                </div>
            </div>
        )
    }
    /**
     */
    showVerification = (val) =>
    {
        this.setState({verification:val})
    }
    /**
     */
    SignUp = async () =>
    {
        try
        {
            const aws_core = new Core( {cn:'440'} )

            const {username, password} = this.state

            if( !username || !password )
                throw new Error( 'Please enter phone number and password' )

            this.setState({loading: true})

            const cfg = this.props.__cfg

            const signup_data = this.state.auth_opt === "email" ?
                await aws_core.SignUpEmail( {username: this.state.username, password} )
                :
                await aws_core.SignUp( {username: 'usr_'+ this.state.cn_code.replace(/[+]/g, '') + this.state.username, password, phone_no: this.state.cn_code.replace(/[+]/g, '') + username} )

            this.setState({loading: false,errMsg:null})

            if(!signup_data.userConfirmed)
            {
                if(this.state.auth_opt === "email")
                    navigate( '/auth/email/verification', {state: {username: this.state.username, cn_code: this.state.cn_code}} )
                else
                    navigate( '/auth/verification', {state: {phone_no: this.state.username, cn_code: this.state.cn_code}} )
            }
            else
                console.warn( "SignUp : SignUp : user confirmed." );

        }
        catch( err )
        {
            console.warn( 'SignUp: SignUp: err: ', JSON.stringify(err) );

            this.setState({loading: false, errMsg:err.message });

            // return Promise.reject( err )
        }
    }
    /**
     */
    componentDidMount()
    {
    }
}   // class Auth_SignUp

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( AuthSignUp )


