/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import { Router } from "@reach/router"

import ChatHome from './home'
import ChatInst from './inst'
import ChatSettings from './settings'

import P404 from '../_common/404'

/**
 */
class App_Chat extends React.PureComponent
{
    /**
     */
    // constructor( props )
    // {
    //     super( props )
    // }

    /**
     */
    render()
    {
        return (
            <Router>
                <ChatHome path='home'/>
                <ChatInst path='inst' />
                <ChatSettings path='settings' />
                <P404 default />
            </Router>
            )
    }   // render
}   // class App_Chat

/**
 */
export {App_Chat, ChatHome, ChatInst, ChatSettings}
