/**
 * @copyright    Elmelo Ltd.
 */

import AWS from 'aws-sdk';
import {Auth} from 'aws-amplify';
import {AStorage} from './Utils';
import elml_config from "../_config/elml_cfg";

AWS.config.update({dynamoDbCrc32: false});

/**
 */
class Core
{
    /**
     *    {
     *      region: 'eu-west-1'         // optional
     *  }
     */
    constructor(p)
    {
        if( !p ) p = {};

        this._region = p.region ? p.region : elml_config._region;
        this._cn_def = p.cn ? p.cn : elml_config.cn_code;
        this._cn = this._cn_def;

        this._cred = p.credentials ? p.credentials : null;
    }

    /**
     */
    async SignUp( p )
    {
        try
        {
            let usr_name = ''

            if( !p.phone_no )
                throw new Error('Phone number was not provided.');

            if( '+' !== p.phone_no.charAt(0) )
                p.phone_no = '+'+p.phone_no

            if( p.username )
            {
                usr_name = p.username
            }
            else
            {
                if( p.cn )
                    this._cn = p.cn;

                usr_name = this._UsrName( p )
            }

            if( !usr_name || !usr_name.length )
                throw new Error('Username is not available.')

            if( !p.password )
                throw new Error('Password was not provided.')

            console.log( "AWS : SignUp : usr_name : ", usr_name );

            return await Auth.signUp({
                    username: usr_name
                ,   password: p.password
                ,   email: p.email
                ,   attributes: {
                        phone_number: p.phone_no
                        // ,    email: this.state.email
                    }
                });
        }
        catch( err )
        {
            console.error( "AWS : SignUp : err : ", JSON.stringify(err) );
            return Promise.reject( err );
        }
    }
    /**
     */
    async SignUpEmail( p )
    {
        try
        {


            if( !p.username )
                throw new Error('Username is not available.');

            if( !p.password )
                throw new Error('Password was not provided.');

            console.log("pppp", p);

            return await Auth.signUp({
                    username: p.username
                ,   password: p.password
                ,   attributes: {
                        email: p.username
                    }
                });
        }
        catch( err )
        {
            console.error( "AWS : SignUpEmail : err : ", JSON.stringify(err) );
            return Promise.reject( err );
        }
    }

    /**
     */
    async SignUp_Confirm( p )
    {
        try
        {
            let usr_name = ''

            if( p.username )
            {
                usr_name = p.username
            }
            else
            {
                if (!p.phone_no)
                    throw new Error('Phone number was not provided.');

                if( p.cn )
                    this._cn = p.cn;

                usr_name = this._UsrName( p )
            }
            console.log( "AWS : SignUp_Confirm : usr_name : ", usr_name );

            return await Auth.confirmSignUp(usr_name, p.auth_code);
        }
        catch( err )
        {
            console.error( "AWS : SignUp_Confirm : err : ", JSON.stringify(err) );
            return Promise.reject( err );
        }
    }

    /**
     */
    async SignUp_Resend( p )
    {
        try
        {
            return await Auth.resendSignUp(p.username);
        }
        catch( err )
        {
            console.error( "AWS : SignUp_Resend : err : ", JSON.stringify(err) );
            return Promise.reject( err );
        }
    }

    /**
     *    {
     *      cn: '44'            // optional
     *  ,   phone_no: ''        // mandatory
     *  ,   password: ''        // mandatory
     *  }
     */
    async SignIn( p )
    {
        try
        {
            let usr_name = ''

            if( p.username )
            {
                usr_name = p.username
            }
            else
            {
                if (!p.phone_no)
                    throw new Error('Phone number was not provided.');

                if( p.cn )
                    this._cn = p.cn;

                usr_name = this._UsrName( p )
            }
            console.log( "AWS : SignIn : usr_name : ", usr_name );

            const user = await Auth.signIn(usr_name, p.password);

            // get all the credentials and id

            // this.StorageSet('phone_no', "+"+this._cn + p.phone_no);

            //
            return user;
        }
        catch (err) {
            // throw err;
            console.error( "AWS : SignIn : err : ", err );
            return Promise.reject( err );
        }
    }

    async signOut()
    {
        try
        {
            return await Auth.signOut() ;
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     */
    _UsrName(p)
    {
        return 'usr_' + this._cn + p.phone_no;
    }

    /**
     *    {
     *      b_retrieve: false           // optional
     *  }
     */
    async Credentials()
    {
        try
        {
            const cur_cred = await Auth.currentCredentials();
            this._cred = await Auth.essentialCredentials( cur_cred );

            return this._cred;
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     */
    async currentuser()
    {
        try
        {
            return await Auth.currentAuthenticatedUser( {
                    bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
                } );
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     */
    async Id()
    {
        try
        {
            this._cred = await Auth.essentialCredentials( await Auth.currentCredentials() );

            return this._cred ? this._cred.identityId : '';
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     */
    async Name()
    {
        try
        {
            const cur_cred = await Auth.currentCredentials();
            const user = await Auth.currentAuthenticatedUser();
            const attr = user.attributes;

            return {title: attr['custom:title'], first: attr.given_name, last: attr.family_name};
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     */
    async Phone()
    {
        try
        {
            const cur_cred = await Auth.currentCredentials();
            const user = await Auth.currentAuthenticatedUser();
            const attr = user.attributes;

            return {number: attr.phone_number, bVerified: attr.phone_number_verified};
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     */
    async Email()
    {
        try
        {
            const cur_cred = await Auth.currentCredentials();
            const user = await Auth.currentAuthenticatedUser();
            const attr = user.attributes;

            return {addr: attr.email, bVerified: attr.email_verified};
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    async SetName( name_obj )
    {
        try
        {
            const cur_cred = await Auth.currentCredentials();

            let user = await Auth.currentAuthenticatedUser();

            const p_auth = {
                    'custom:title': name_obj.title,
                    given_name: name_obj.first,
                    family_name: name_obj.last
                }

            let res_upd = await Auth.updateUserAttributes( user, p_auth );

            await Auth.currentAuthenticatedUser({ bypassCache: true })

            return res_upd;
        }
        catch( err )
        {
            console.warn( 'AWS/Core: SetName: err: ', err )
            return {err};
        }
    }

    async SetEmail( email_addr )
    {
        try
        {

            let user = await Auth.currentAuthenticatedUser();

            const resp = await Auth.updateUserAttributes( user, {'email': email_addr} )
            await Auth.currentAuthenticatedUser({ bypassCache: true })

            return resp

        }
        catch( err )
        {
            console.warn("err", err)
            return {err};
        }
    }

    async EmailVerification()
    {
        try
        {
            // const cur_cred = await Auth.currentCredentials();
            return await Auth.verifyCurrentUserAttribute( 'email' )
        }
        catch( err )
        {
            return Promise.reject( err )
        }
    }

    async EmailVerificationSubmit( veri_code )
    {
        try
        {
            // const cur_cred = await Auth.currentCredentials();
            return await Auth.verifyCurrentUserAttributeSubmit( 'email', veri_code )
        }
        catch( err )
        {
            return Promise.reject( err )
        }
    }

    /**
     */
    async PhoneVerification()
    {
        try
        {
            // const cur_cred = await Auth.currentCredentials();
            return await Auth.verifyCurrentUserAttribute( 'phone_number' )
        }
        catch( err )
        {
            return Promise.reject( err )
        }
    }

    /**
     */
    async PhoneVerificationSubmit( veri_code )
    {
        try
        {
            // const cur_cred = await Auth.currentCredentials();
            return await Auth.verifyCurrentUserAttributeSubmit( 'phone_number', veri_code )
        }
        catch( err )
        {
            return Promise.reject( err )
        }
    }

    /**
     */
    async StorageGet(key)
    {
        try
        {
            return await AStorage.Get(await this.Id() + ':' + key);
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     */
    async StorageSet(key, val)
    {
        try
        {
            return await AStorage.Set(await this.Id() + ':' + key, val);
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }
    /**
     */
    async StorageRem(key, val)
    {
        try
        {
            return await AStorage.RemoveItem(await this.Id());
        }
        catch( err )
        {
            return Promise.reject( err );
        }
    }

    /**
     * @param p
     * @returns {Promise.<*>}
     */
    async Request_Forgot_Password(p){
        if(p.username)
            return await Auth.forgotPassword(p.username);
        else
            return await Auth.forgotPassword('usr_'+p.phone_no);
    }

    /**
     * @param p
     * @param code
     * @param password
     * @returns {Promise.<*>}
     */
    async Submit_Forgot_Password(p){
        return await Auth.forgotPasswordSubmit(p.username, p.auth_code, p.password);
    }

    //
}   // class AWS

/**
 */
// class DDB extends Core   {
class DDB
{
    constructor(p)
    {
        this._core = p.core ? p.core : new Core( {} );
        this._region = "eu-west-1" ;
        this._endPoint = 'https://dynamodb.' + this._region + '.amazonaws.com';
        this._bInit = false;
    }

    async Init(p)
    {
        if (this._bInit && !p.b_init)
            return {msg: 'OK'};

        this._endPoint = 'https://dynamodb.' + this._region + '.amazonaws.com';

        return {msg: 'OK'};
    }

    Client = () => {
        return new Promise( async ( sol, rej ) => {
            try{
                const client = new AWS.DynamoDB.DocumentClient({
                    region: this._region
                    ,   endpoint: this._endPoint
                    ,   credentials: await this._core.Credentials()
                });

                return sol( client );
            }
            catch( err ){
                return rej(err);
            }
        } );
    }

    Set = (p) => {
        return new Promise( async ( sol, rej ) => {
            try{
                const client = await this.Client();

                client.put(p, (err, data) => {
                    if (err)
                        return rej(err);

                    return sol( data );
                });
            }
            catch( err ){
                return rej(err);
            }
        } );
    }

    Get = (p) => {
        return new Promise( async ( sol, rej ) => {
            try{
                const client = await this.Client();

                client.get(p, (err, data) => {
                    if (err)
                        return rej(err);

                    return sol( data );
                });
            }
            catch( err ){
                return rej(err);
            }
        } );
    }
    /**
     */
    Del( params, p )
    {
        return new Promise( async ( sol, rej ) => {
            try{
                const client = await this.Client();

                client.delete(p, (err, data) => {
                    if (err)
                        return rej(err);

                    return sol( data );
                });
            }
            catch( err ){
                return rej(err);
            }
        } );
    }   // function Get_P
    /**
     *  \todo Need to add number of attempts.
     *
     *  var params = {tables: [ {name: '', keys: [{}]}, proj_exp: '', attr_names: {}, attr_vals: {} ], ret_con_cap: ''}
     */
    BatchGet = (p) => {
        return new Promise( async ( sol, rej ) => {
            try{
                const client = await this.Client();

                /** Request Syntax
                    {
                       "RequestItems": {
                          "string" : {
                             "AttributesToGet": [ "string" ],
                             "ConsistentRead": boolean,
                             "ExpressionAttributeNames": {
                                "string" : "string"
                             },
                             "Keys": [
                                {
                                   "string" : {
                                      "B": blob,
                                      "BOOL": boolean,
                                      "BS": [ blob ],
                                      "L": [
                                         "AttributeValue"
                                      ],
                                      "M": {
                                         "string" : "AttributeValue"
                                      },
                                      "N": "string",
                                      "NS": [ "string" ],
                                      "NULL": boolean,
                                      "S": "string",
                                      "SS": [ "string" ]
                                   }
                                }
                             ],
                             "ProjectionExpression": "string"
                          }
                       },
                       "ReturnConsumedCapacity": "string"
                    }
                 */
                let p_get = { RequestItems: {} };

                p.tables.forEach( tbl => {
                    if( !p_get.RequestItems[tbl.name] )
                        p_get.RequestItems[tbl.name] = { Keys: [] }

                    tbl.keys.forEach( key => {
                        p_get.RequestItems[tbl.name].Keys.push( key );
                    } );

                    if( tbl.ConsistentRead )
                        p_get.RequestItems[tbl.name].ConsistentRead = tbl.ConsistentRead;

                    if( tbl.proj_exp )
                        p_get.RequestItems[tbl.name].ProjectionExpression = tbl.proj_exp;

                    if( tbl.attr_names )
                        p_get.RequestItems[tbl.name].ExpressionAttributeNames = tbl.attr_names;

                    if( tbl.attr_vals )
                        p_get.RequestItems[tbl.name].ExpressionAttributeNames = tbl.attr_vals;

                    if( tbl.ReturnConsumedCapacity )
                        p_get.RequestItems[tbl.name].ReturnConsumedCapacity = tbl.ReturnConsumedCapacity;
                } );

                client.batchGet(p_get, (err, data) => {
                    if (err)
                        return rej(err);

                    return sol( data );
                });
            }
            catch( err ){
                return rej(err);
            }
        } );
    }

    Upd = (p) => {
        return new Promise( async ( sol, rej ) => {
            try{
                const client = await this.Client();

                client.update(p, (err, data) => {
                    if (err)
                        return rej( err);

                    return sol( data );
                });
            }
            catch( err ){
                return rej(err);
            }
        } );
    }

    Query = (p) => {
        return new Promise( async ( sol, rej ) => {
            try{
                const client = await this.Client();

                client.query(p, (err, data) => {
                    if (err)
                        return rej(err);

                    return sol( data );
                });
            }
            catch( err ){
                return rej(err);
            }
        } );
    }

    Scan = (p) => {
        return new Promise( async ( sol, rej ) => {
            try{
                const client = await this.Client();

                client.scan(p, (err, data) => {
                    if (err)
                        return rej(err);

                    return sol( data );
                });
            }
            catch( err ){
                return rej(err);
            }
        } );
    }
}   // class DDB

/**
 */
class Lambda extends Core
{
    /**
     */
    constructor(p)
    {
        super(p);

        this._endPoint = elml_config._lambda_endPoint;
    }

    async Init(p) {
        if( !p ) p = {};

        if (this._bInit && !p.b_init)
            return {msg: 'OK'};
        return {msg: 'Initialized!!'};
    }

    /**
     */
    Invoke(params,func_name)
    {
        return new Promise( async (sol, rej) => {

            try
            {
                // await this.Init( {b_init: true} );
                const lambda = new AWS.Lambda({
                    apiVersion: '2015-03-31'
                    ,   region: this._region
                    ,   endpoint: this._endPoint
                    ,   credentials: await this.Credentials()
                });

                const params_lambda = {
                    FunctionName: func_name, /* required */
                    InvocationType: "RequestResponse",
                    LogType: "Tail",
                    Payload: JSON.stringify(params)
                };

                lambda.invoke(params_lambda, (err, data) => {
                    if (err)
                        return rej( err );

                    try
                    {
                        data = JSON.parse(data.Payload);
                    }
                    catch (err)
                    {
                        data = data.Payload;
                    }

                    return sol( data );
                });
            }
            catch( err )
            {
                return rej( err );
            }
        });
    }   // Invoke
}   // class Lambda

/**
 */
class S3
{
    /**
     */
    static async Upload( p )
    {
        return new Promise( async (res, rej) => {
            const aws_core = new Core( {} );

            const s3_obj = new AWS.S3({
                    apiVersion: '2015-03-31',
                    region: "eu-west-1",
                    credentials: await aws_core.Credentials(),
                });

            const p_s3 = {
                    Bucket: p.bucket
                ,   Key: p.key
                ,   Body: p.data
                };

            s3_obj.upload( p_s3, (err, data) => {
                if( err )
                    return rej( err );

                return res( data );
            } );
        } );
    }   // Upload

    /**
     */
    static async SignedUrl( p )
    {
        return new Promise( async (res, rej) => {
            const aws_core = new Core( {} );

            const s3_obj = new AWS.S3({
                    apiVersion: '2015-03-31',
                    region: "eu-west-1",
                    credentials: await aws_core.Credentials(),
                });

            const p_s3 = {
                    Bucket: p.bucket
                ,   Key: p.key
                ,   Expires: /*p.duration ? p.duration :*/ (365 * 24 * 60 * 60) // 1 year
                };

            s3_obj.getSignedUrl( 'getObject', p_s3, (err, data) => {
                if( err )
                    return rej( err );

                return res( data );
            } );
        } );
    }

    /**
     */
    static async SignedUrl_Put( p )
    {
        return new Promise( async (res, rej) => {
            const aws_core = new Core( {} );

            const s3_obj = new AWS.S3({
                    apiVersion: '2015-03-31',
                    region: "eu-west-1",
                    credentials: await aws_core.Credentials(),
                });

            const p_s3 = {
                    Bucket: p.bucket
                ,   Key: p.key
                ,   ContentType: 'image/jpeg'
                // ,   Expires: (1 * 60) // 1 min
                // default expiry is 15 min
                };

            s3_obj.getSignedUrl( 'putObject', p_s3, (err, data) => {
                if( err )
                    return rej( err );

                return res( data );
            } );
        } );
    }
}   // S3

/**
 */
export { DDB, Core, Lambda, S3};

