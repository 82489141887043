/**
 * @copyright Elmelo Ltd.
 */
import React , {Component} from 'react'

import {
    Container,
    Form,
    Button,
    Alert,
    Spinner
} from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import {Core , Lambda,DDB } from "../../api";
import {navigate,Redirect} from "@reach/router";
import Hdr from '../_common/hdr'
import ChatIoT from "../../api/chat/chat_iot";
import './home.css'
/**
 */
class Home extends Component
{
    constructor( props )
    {
        super( props )
        this.state = {
            bInit: true
        }
    }
    /**
     */
    render()
    {

        if (this.props.__core.bizInfo)
        {
            return (
                <div>
                    <Hdr/>
                    <div style={{marginTop:84,display:"flex"}}>
                        <div className="bizBox"
                             onClick={()=>navigate( 'chat' )}
                        >
                            <div className="bizText">
                                Chat
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop:84,display:"flex"}}>
                        <div className="bizBox">
                            <div className="bizText">
                                To complete your business information please download our Histreet(Business) app from
                                Playstore: https://play.google.com/store/apps/details?id=com.histreet.business&hl=fr_CH
                                Appstore: https://apps.apple.com/us/app/histreet-business/id1494624906
                            </div>
                        </div>
                    </div>

                </div>
            )
        }
        else
        {
            return <Redirect to="/settings" noThrow={true}/>

        }

    }
    /**
     */
    componentDidMount()
    {
        // if(this.props.__core.authStatus !== 'auth')
        //     return navigate('/auth/signin')
        // else if(!this.props.__core.bizInfo)
        //     return navigate('/settings')

        if(this.props.__core.authStatus == 'auth' && this.props.__core.bizInfo)
        {
            this.props.Rdx_Chat_Init()
        }

    }

}   // class Home

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Home )


