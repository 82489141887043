/**
 *	@copyright	Elmelo Ltd
 */

///
// const aws_cfg = {
//         'aws_cognito_region': 'eu-west-1',
//         'aws_cognito_identity_pool_id': 'eu-west-1:ff965caa-8150-4546-a483-ef87eb6611f9',
//         'aws_user_pools_id': 'eu-west-1_J9SM4S3oo',
//         'aws_user_pools_web_client_id': '3grsef7bg190ikieh5l241um17',
// }

///new
// const aws_cfg = {
//         'aws_cognito_region': 'eu-west-1',
//         'aws_cognito_identity_pool_id': 'eu-west-1:351ac416-a077-4abc-a6ef-cc46d77142d1',
//         'aws_user_pools_id': 'eu-west-1_jNLSihQhF',
//         'aws_user_pools_web_client_id': '7gslipq5narvt4j1boca2u314e',
// }

export function aws_cfg(type)
{
        if (type === "email")
        {
                return {
                        'aws_cognito_region': 'eu-west-1',
                        'aws_cognito_identity_pool_id': 'eu-west-1:351ac416-a077-4abc-a6ef-cc46d77142d1',
                        'aws_user_pools_id': 'eu-west-1_jNLSihQhF',
                        'aws_user_pools_web_client_id': '7gslipq5narvt4j1boca2u314e',
                }
        }
        else
        {
                return {
                        'aws_cognito_region': 'eu-west-1',
                        'aws_cognito_identity_pool_id': 'eu-west-1:ff965caa-8150-4546-a483-ef87eb6611f9',
                        'aws_user_pools_id': 'eu-west-1_J9SM4S3oo',
                        'aws_user_pools_web_client_id': '3grsef7bg190ikieh5l241um17',
                }
        }


}

///
// export default aws_cfg


