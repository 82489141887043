/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {
    Container,
    Form,
    Button, Alert
} from 'react-bootstrap'
import './css/auth.css'
import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import color from '../_common/colors.json'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimes,faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import {navigate} from "@reach/router";
import {TextInput,Password_SignUp} from '../_common/component'
import {Core} from "../../api"
// import elml_cfg from '../../_config/elml_cfg'
import countryCode from './CountryCode'
import {PickerEx} from "../_common/picker";

/**
 */
class AuthForgotten extends React.PureComponent
{
    constructor( props )
    {
        super( props )
        this.state={

            username: this.props.location.state.username,
            verificationView: false,
            password: "",
            errMsg: "",
            b_loading: false,
            cn_code : '+44',
            f_success: false,
            code: ''
        }
    }
    /**
     */
    render()
    {

        if(this.state.f_success)
        {

            return(

                <div>
                    {/*<NavHdr signin={true}/>*/}

                    <div className="authContainer" style={{textAlign:"center"}}>
                        <FontAwesomeIcon
                            style={{marginTop:16,marginBottom:16}}
                            icon={faCheckCircle}
                            size="5x"
                            color={"green"}/>
                        <p style={{color:"green"}}>You have changed your password successfully  </p>
                        <div
                            className="authLittle">
                            <i onClick={() => navigate("signin")} variant="light" className="highlight"
                               style={{color: "#C94B4B"}}>Sign In Now</i>
                        </div>
                    </div>

                </div>
            )

        }

        if(this.state.verificationView){

            return(

                <div>
                    {/*<NavHdr signin={true} />*/}
                    <div className="authTitle">
                        Change Password
                    </div>
                    <div className="authContainer">
                        <div
                            onClick={() => this.setState({verificationView: false})}

                            className="buttonAuthDiv">
                            <FontAwesomeIcon
                                className="buttonAuth"
                                icon={faTimes}
                                size="lg"
                                color={"red"}/>
                        </div>
                        <Form.Group style={{flexDirection: "row", display: "flex"}}>
                            {/*<div className="mobileCode"> +440</div>*/}
                            <div style={{flex: 1}}>
                                <TextInput
                                    placeholder="Enter Code"
                                    type="number"
                                    size="lg"
                                    val={this.state.code}
                                    onChange={(val)=>this.setState({code: val.target.value })}
                                    className="numberHide"
                                />
                            </div>
                        </Form.Group>

                        <Password_SignUp
                            OnChange={(val) => { this.setState( {password: val} ) } }
                        />
                        {
                            this.state.errMsg?
                                <Alert variant="danger" onClose={() => this.setState({errMsg: ""})} dismissible>
                                    {this.state.errMsg}
                                </Alert> : null
                        }
                        <Form.Group>
                            <Button
                                onClick={this.changePass}
                                style={{width:"100%",
                                    background: "linear-gradient(to right, #C94B4B, #4B134F)",
                                    display: "flex", justifyContent: "center"}}
                                size="lg"
                            >
                                Confirm
                            </Button>
                        </Form.Group>
                    </div>
                </div>
            )
        }

        return (
            <div>
                {/*<NavHdr signin={true} />*/}

                <div className="signinContainer">
                    <div className="authTitle">
                        Verify Your Mobile Number
                    </div>
                    <div className="authContainer">
                        <div
                            onClick={() => navigate("signin")}

                            className="buttonAuthDiv">
                            <FontAwesomeIcon
                                className="buttonAuth"
                                icon={faTimes}
                                size="lg"
                                color={"red"}/>
                        </div>
                        <Form.Group>
                            <div className="authLittle">
                                We will send an <i className="highlight">HISTREET</i> code to your mobile. That code will be used to verify your mobile number and you will be able to change your password afterwards.
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <PickerEx
                                items={countryCode}
                                prompt={"Country"}
                                val={this.state.cn_code}
                                bSearch={true}
                                onChange={(val) => this.setState({cn_code: val}) }
                                className="textBoxProfile"
                            />
                            {/*<div className="mobileCode"> +440</div>*/}
                            <TextInput
                                placeholder="Enter phone number"
                                type="number"
                                size="lg"
                                val={this.state.username}
                                onChange={(val)=> this.setState({username: val.target.value.replace(/\s+/g, '').replace(/^(0{1,})/, '') })}
                                className="numberHide"
                            />
                        </Form.Group>
                        {
                            this.state.errMsg?
                                <Alert variant="danger" onClose={() => this.setState({errMsg: ""})} dismissible>
                                    {this.state.errMsg}
                                </Alert> : null
                        }
                        <Form.Group>
                            <Button
                                onClick={this.reqforgetPass}
                                style={{width: "100%",
                                    background: "linear-gradient(to right, #C94B4B, #4B134F)",
                                    display: "flex", justifyContent: "center"}}
                                size="lg"
                                variant="info">Request HISTREET Code
                            </Button>
                        </Form.Group>
                    </div>
                </div>
            </div>
        )
    }
    /**
     */
    reqforgetPass = () =>
    {


        try{

            this.setState({b_loading: true });

            const aws_core = new Core({});

            const {username , cn_code} = this.state;

            const cfg = this.props.__cfg

            if( !username)
                throw new Error( 'Please enter phone number' );

            aws_core.Request_Forgot_Password(this.props.location.state.auth_email ? {username: username } : {phone_no:  cn_code.replace(/[+]/g, '') + username})
                .then( data => {

                    if(data)
                        this.setState({verificationView: true});
                })
                .catch( err => {
                    this.setState({errMsg:err.message });
                });

        }catch (err) {

            console.warn( 'SignUp: SignUp: err: ', err )
            this.setState({b_loading: false, errMsg:err.message });
        }

    }
    /**
     */
    changePass = () =>
    {
        this.setState({b_loading: true});

        const {username,cn_code,code,password} = this.state;
        const aws_core = new Core({});

        const user_name = this.props.location.state.auth_email ? username : 'usr_' + cn_code.replace(/[+]/g, '') + username ;

        aws_core.Submit_Forgot_Password({username: user_name , auth_code: code, password: password})
            .then( data => {

                // // console.log(data);
                this.setState({f_success: true})

            })
            .catch(err => {
                // console.log(err);
                this.setState({loading: false,errMsg:err.message });
            })

    }
}   // class AuthForgotten

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( AuthForgotten )


