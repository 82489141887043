
/**
 * @copyright Elmelo Ltd.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import reducers from './rdx/reducers'

import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import './index.css';
import App from './App'

import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify'
import {aws_cfg} from './_config/aws_cfg'

Amplify.configure(aws_cfg("email"));

const rdx_store = createStore( reducers, applyMiddleware(thunk) )
const rdx_persistor = persistStore( rdx_store )

ReactDOM.render(
    <Provider store={rdx_store}>
        <PersistGate persistor={rdx_persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

