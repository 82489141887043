/**
 *  @copyright  Elmelo Ltd.
 */

import {combineReducers} from 'redux'
import Cfg from './Cfg'
import Core from './Core'
import Chat from './Chat'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

/**
 */
const persist_cfg = {
        key: 'root',
        storage: storage,
        whitelist: ['__core','__chat'],
}

/**
 */
// export default combineReducers({
//     __cfg: Cfg,
//     __core: Core,
//     __chat: Chat,
//
// })
/**
 */
const rdx_reduers = combineReducers( {
    __cfg: Cfg,
    __core: Core,
    __chat: Chat,
} )

/**
 */
export default persistReducer( persist_cfg, rdx_reduers )
