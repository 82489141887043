/**
 *	@copyright	Elmelo Ltd.
 */

export const Rdx_Cfg_Stage = ( cfg_stage) =>
{
    return {
            type: 'cfg:stage',
            payload: cfg_stage,
        }
}   // Rdx_Cfg_Stage
