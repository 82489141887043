/**
 * @copyright Elmelo Ltd.
 */
import React , {Component} from 'react'

import {
    Container,
    Form,
    Button,
    Alert,
    Spinner
} from 'react-bootstrap'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'
import {Core , Lambda,DDB } from "../../api";
import {navigate} from "@reach/router";
import Hdr from '../_common/hdr'
import './settings.css'
/**
 */
class Settings extends Component
{
    constructor( props )
    {
        super( props )
        this.state = {

            bRetrieving: true,
            alphas: [],
        }
    }
    /**
     */
    render()
    {
        return (
            <div>
                <Hdr/>
                <div style={{marginTop:84,display:"flex"}}>
                    {
                        this.state.alphas.map((item, idx) => (

                            <div
                                className="bizBox"
                                onClick={() => this.bizSelect(item)}
                                key={idx}
                                style={{
                                    background: this.props.__core.bizInfo &&
                                        this.props.__core.bizInfo.biz_id === item.biz_id ? 'linear-gradient(to right, #159957, #155799)' : null
                                }}
                            >
                                <div
                                    style={{
                                    color: this.props.__core.bizInfo &&
                                    this.props.__core.bizInfo.biz_id === item.biz_id ? '#fff' : null
                                }}
                                    className="bizText"
                                >
                                    {item.biz_title ? item.biz_title : item.biz_name}
                                </div>
                            </div>

                        ))
                    }
                </div>
            </div>
        )
    }
    /**
     */
    componentDidMount()
    {
        this.RetrieveAlphas()
            .then( data => {} )
            .catch( err => {} )
    }
    /**
     */
    RetrieveAlphas = async () =>
    {
        try
        {
            this.setState({bRetrieving:true})

            const cfg = this.props.__cfg

            const aws_lambda = new Lambda( {} )

            const p_lambda = {
                usr: 'alpha',
                actType: 'users',
                act: 'list:bizbyuser',
                stage: cfg.stage,
            }

            const resp_lambda = await aws_lambda.Invoke( p_lambda, cfg.lambda('alpha', cfg.stage) )

            console.log( 'Settings_Account: RetrieveAlphas: resp_q: ', resp_lambda )

            if( !resp_lambda.length )
            {
                this.setState( {alphas: [], bRetrieving: false} )

                return {msg: 'OK'}
            }

            const ddb_obj = new DDB({})

            const tbl_name = cfg.db( 'biz', cfg.stage )

            const param_bget = { tables: [{
                    name: tbl_name
                    ,	keys: resp_lambda.map( x => { return {biz_id: x.biz_id} } )
                    ,	proj_exp: ['biz_id',
                        'biz_name', 'biz_cat', 'biz_subcats',
                        'biz_title','biz_type'
                    ].join(',')
                }] }

            const bget_result = await ddb_obj.BatchGet( param_bget )

            const resp_bget = bget_result.Responses[tbl_name]

            console.log( 'RetrieveAlphas: resp_bget: ', resp_bget )

            this.setState( {alphas: resp_bget, bRetrieving: false} )

            return {}
        }
        catch( err )
        {
            this.setState( {bRetrieving: false} )

            console.warn( 'RetrieveAlphas: err: ', err )

            return Promise.reject( err )
        }
    }   // RetrieveAlphas
    /**
     */
    bizSelect = async (item) =>
    {
        try
        {
            if(this.props.__core.bizInfo && this.props.__core.bizInfo.biz_id !== item.biz_id)
            {
                this.props.Rdx_Chat_Clear()
            }

            this.props.Rdx_BizInfo( item );
            // this.props.Rdx_Pubid_Init();

            return navigate( 'home' )

        }
        catch( err )
        {
            this.setState( {bRetrieving: false} )

            console.warn( 'Settings_Account: bizSelect: err: ', err )

            return Promise.reject( err )
        }
    }   // RetrieveAlphas

}   // class Home

/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Settings )


