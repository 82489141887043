/**
 *	@copyright	Elmelo Ltd
 */

/**
 */
export default ( state = {
		bInit: false,
		status: 'new',
		sb: null,
		elml: null,
		totUnRead: {},
	 	totUnRead_ReqCnt: 0,
	 	totUnRead_ReqStatus: 'stale',
		pub_id: null,
		channelList:[],
		ts: 0,
		ch: null

				 },
	action ) =>
{
	switch( action.type )
	{
	case 'chat:init_inprogress':
		return {...state, bInit: false, status: 'init_inprogress'}

	case 'chat:init_complete':
		return {...state, bInit: true, status: 'init_complete'}

	case 'chat:elml':
		return { ...state, elml: action.payload }

	case 'chat:tot_unread_upd':
		return { ...state, totUnRead: action.payload }

	case 'chat:tot_unread_req_cnt':
		return { ...state, totUnRead_ReqCnt: action.payload }

	case 'chat:tot_unread_req_status':
		return { ...state, totUnRead_ReqStatus: action.payload }

	case 'chat:user-id':
		return { ...state, pub_id: action.payload }

	case 'chat:channel':
		return { ...state, channelList: action.payload }

	case 'chat:channel:upd':
		const idx = state.channelList.findIndex( x => x.ch_id === action.payload.msg_obj.ch_id )
		const newList = [...state.channelList];

		if(idx !== -1)
		{
			newList[idx] = {...newList[idx], _nUnRead: action.payload._nUnRead , _last: action.payload.msg_obj}
		}
		return { ...state,channelList: newList , ts: Date.now() }

	case 'chat:status':
		return { ...state,status: action.payload }

	case 'set:ch':
		return { ...state, ch: action.payload ? action.payload : state.channelList.length ? state.channelList[0] : null }

	case 'chat:clear':
		return { ...state,
			bInit: false,
			status: 'new',
			sb: null,
			elml: null,
			totUnRead: {},
			totUnRead_ReqCnt: 0,
			totUnRead_ReqStatus: 'stale',
			channelList:[]
		}
	default:
		return state;
	}	// switch action.type
}	// ...



