/**
 * @copyright Elmelo Ltd.
 */

import React from 'react'

import {connect} from 'react-redux'
import * as actions from '../../rdx/actions'

import{
    // Form,
    Row,Col,Image
} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    // faCheck, faPlusSquare,
    faUser
} from '@fortawesome/free-solid-svg-icons'
// import color from "../_common/colors";
import Loader from 'react-loader-spinner'
import chatImg from  './img/chat.png'
import HSB from "../_common/images/HSB.png";
import {Navbar} from "react-bootstrap/Navbar";
import '../_common/css/_common.css'
/**
 */
class Chat_Inst extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )
    }

    /**
     */
    render()
    {
        console.log("chat init state", this.props)

        if(!this.props._ch)
        {
            return (

                <div className="noChat">
                    <Image
                        className="chatImage"
                        src={chatImg}/>
                    <h4>Select a Channel to Start Messaging</h4>
                </div>
            )
        }
        return (

            <div>
            {
                !this.props.bInit
                ?
                <div className="noChat">
                    <Loader
                        type="Bars"
                        color="#155799"
                        height={200}
                        width={200}
                        timeout={30000000}

                    />
                </div>
                :
                this.props.msgList.length
                ?

                this.props.msgList.map((item, idx) => (

                    this.props.__core.bizInfo.biz_id === item.usr_id ?

                        <Chat_Own
                            item={item}
                            key={idx}
                            dt_obj={new Date(item.dt_create)}
                        />
                        :
                        <Chat_Other
                            item={item}
                            key={idx}
                            dt_obj={new Date(item.dt_create)}
                        />

                ))
                :
                    <div className="imageText">
                        <Image
                            className="chatImage"
                            src={chatImg}/>
                        <div className="alertMessage">
                            Start messaging with us.
                        </div>
                    </div>

            }
            </div>
        )
    }

    /**
     */
    componentDidMount() {
        // document.getElementsByClassName("messageBody")[0].addEventListener("scroll", this.handleScroll);
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        // document.getElementsByClassName("messageBody")[0].removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("scroll", this.handleScroll);
    }
    handleScroll = () => {

        // console.log("page position", window.pageYOffset)

        if(window.pageYOffset === 0)
        {

            // console.log("page position", window.pageYOffset)
            this.props.fetchMoreData()
                .then()
                .catch()
        }
    };
}   // class Chat_Inst


/**
 */
class Chat_Own extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )
    }

    /**
     */
    render()
    {
        if(this.props.item.type === 'order')
        {
            return (

                <RenderOrder {...this.props} own={true}/>
            )
        }
        if(this.props.item.type === 'rsrv')
        {
            return (

                <RenderRsrv {...this.props} own={true}/>
            )
        }
        return (
            <div className="restChatDiv">
                <div>
                    <div className="infoDescription" style={{textAlign:"right"}}>
                        {this.props.dt_obj.toLocaleDateString()+' '+this.props.dt_obj.toLocaleTimeString()}
                    </div>
                    <div className="restChatBox">
                        <div className="restChatText">
                            {this.props.item.msg}
                        </div>
                    </div>
                </div>
                <FontAwesomeIcon icon={faUser} className="chatIcon" style={{marginLeft: 8,marginRight:0}} color="#b7b7b7"/>
            </div>
        )
    }

    /**
     */
    componentDidMount()
    {
    }
}   // class Chat_Rest
/**
 */
class Chat_Other extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )
    }

    /**
     */
    render()
    {
        if(this.props.item.type === 'order')
        {
            return (

                <RenderOrder {...this.props} />
            )
        }
        if(this.props.item.type === 'rsrv')
        {
            return (

                <RenderRsrv {...this.props} />
            )
        }
        return (
            <div className="userChatDiv">
                <FontAwesomeIcon icon={faUser} className="chatIcon" color="#b7b7b7"/>
                <div>
                    <div className="infoDescription" style={{textAlign:"left"}}>
                        {this.props.dt_obj.toLocaleDateString()+' '+this.props.dt_obj.toLocaleTimeString()}
                    </div>
                    <div className="userChatBox">
                        <div className="userChatText">
                            {this.props.item.msg}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**
     */
    componentDidMount()
    {
    }
}   // class Chat_User
/**
 */
class RenderOrder extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )
    }

    /**
     */
    render()
    {
        // // console.log("order info", this.props.item)
        return (
            <Row style={{marginLeft:16,marginRight:16} }>
                <Col xs="12" md="12" lg="12">
                    <div className="bodyDetailsDiv" style={!this.props.own ? {'backgroundColor': "linear-gradient(to right, #155799, #155799)"} : null} >

                        <div style={{flex: 1}}>
                            <div className="infoDescription"
                                 style={!this.props.own ? {'color': "#fff"} : null}
                            >Order Id</div>
                            <div className="valueProfile">{this.props.item.meta.order_id ? this.props.item.meta.order_id.split('_')[3] : this.props.item.meta.order_uid }</div>
                        </div>
                        <div style={{flex: 1}}>
                            <div className="infoDescription"
                                 style={!this.props.own ? {'color': "#fff"} : null}>date</div>
                            <div className="valueProfile">{this.props.dt_obj.toLocaleDateString()}</div>
                        </div>

                        <div style={{flex: 1}}>
                            <div className="infoDescription"
                                 style={!this.props.own ? {'color': "#fff"} : null}>amount</div>
                            <div className="valueProfile">{parseFloat(this.props.item.meta.Total).toFixed(2)}</div>
                        </div>

                        <div style={{flex: 1}}>
                            <div className="infoDescription"
                                 style={!this.props.own ? {'color': "#fff"} : null}>Order Status</div>
                            <div className="valueProfile">{this.props.item.meta.status}</div>
                        </div>

                    </div>
                </Col>
            </Row>
        )
    }

    /**
     */
    componentDidMount()
    {
    }
}   // class Chat_User
/**
 */
class RenderRsrv extends React.PureComponent
{
    /**
     */
    constructor( props )
    {
        super( props )
    }

    /**
     */
    render()
    {
        const dt_obj = new Date(this.props.item.meta.rsrv_dt_st * 1000 )

        return (
            <Row style={{marginLeft:16,marginRight:16}}>
                <Col xs="12" md="12" lg="12">
                    <div className="bodyDetailsDiv"
                         style={!this.props.own ? {'background': "linear-gradient(to right, #155799, #155799)"} : null}
                    >

                        <div style={{flex: 1}}>
                            <div className="infoDescription"
                                 style={!this.props.own ? {'color': "#fff"} : null}>date</div>
                            <div className="valueProfile">{dt_obj.toLocaleDateString()}</div>
                        </div>


                        <div style={{flex: 1}}>
                            <div className="infoDescription"
                                 style={!this.props.own ? {'color': "#fff"} : null}>Time</div>
                            <div className="valueProfile">{ [dt_obj.getHours() , dt_obj.getMinutes()].join(':') }</div>
                        </div>

                        <div style={{flex: 1}}>
                            <div className="infoDescription"
                                 style={!this.props.own ? {'color': "#fff"} : null}>Guest</div>
                            <div className="valueProfile">{this.props.item.meta.rsrv_nppl}</div>
                        </div>

                        <div style={{flex: 1}}>
                            <div className="infoDescription"
                                 style={!this.props.own ? {'color': "#fff"} : null}>Reservation Status</div>
                            <div className="valueProfile">
                                {   this.props.item.meta.rsrv_status === "req_new" ? "New" :
                                    this.props.item.meta.rsrv_status === "req_accepted" ? "Accepted" :
                                    this.props.item.meta.rsrv_status === "req_declined" ? "Declined" : null
                                }
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }

    /**
     */
    componentDidMount()
    {
    }
}   // class Chat_User
/**
 */
const mapStateToProps = (state) =>
{
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)( Chat_Inst )


