/**
 *	@copyright	Elmelo Ltd
 */

/**
 */
const elml = {
        stage: 'live',
        _lambda_endPoint: 'https://lambda.' + 'eu-west-1' + '.amazonaws.com',
        _region: 'eu-west-1',
        cn_code: '44',
        elmelo_b_shared: true,
        email_address: 'no-reply@histreet-app.com',
        platform: 'hs',
        api_google: 'AIzaSyDQaXKe4roziIjDQAFFC2wdJQrHM9K1o8M',
        lambda: ( name, stage = elml.stage ) =>
        {
            switch( stage )
            {
            case 'test':
                switch( name )
                {
                case 'alpha': return 'histreet-alpha-dev-app-13W9RMNP5W3KI';
                case 'beta': return 'histreet-beta-dev-app-8A4MZAGLW94R'
				case 'push': return 'elmelo-push-app-H6T5Q7BEGLO1'
                case 'chat': return 'elmelo-chat-1-app-181NI3WP6LRY2';

                case 'social': return 'elmeloScanPayment'
				case 'pay': return 'test_elmeloPay_Stripe_v2'
				case 'payv3': return 'elmeloPay_Stripe_v3'
				case 'notify' : return "test_elmeloPay_Notify_v2"

                default: return null;
                }   // // switch

            case 'live':
            default:
                switch( name )
                {
                case 'alpha': return 'histreet-alpha-app-WQDL6J6DEAPT';
				case 'beta': return 'histreet-beta-app-1WKNYNGXKOZ78';
				case 'push': return 'elmelo-push-app-H6T5Q7BEGLO1';
				case 'chat': return 'elmelo-chat-app-WVCFRU0LV7PK';

				case 'social': return 'elmeloScanPayment';
				case 'pay': return 'test_elmeloPay_Stripe_v2';
				case 'payv3': return 'elmeloPay_Stripe_v3';
				case 'notify' : return "test_elmeloPay_Notify_v2";

                default: return null;
                }   // // switch
            }   // switch
		},

        db: ( name, stage = elml.stage ) =>
        {
            switch( stage )
            {
            case 'test':
                switch( name )
                {
				case 'biz': return 'test_elmelo_biz_2'
				case 'geos': return 'test_elmelo_geos_2';
				case 'images': return 'test_elmelo_images_1';
				case 'menu': return 'test_elmelo_menu_1';
				case 'serv': return 'test_elmelo_serv_1';

				case 'order': return 'test_elmelo_order_3';


                case 'biz_info': return 'test_elmelo_rat_1';
				case 'pay_disc': return 'test_elmelo_pay_discount_1';
				case 'soc_event': return 'test_elmelo_event_0';
				case 'soc_cal': return 'test_elmelo_cal_0';
				case 'soc_rsrv': return 'test_elmelo_rsrv_0';
				case 'notify': return 'test_elmelo_notify_2';
				case 'wallet': return 'test_elmelo_wallet_3';

				case 'user:inactive': return 'test_elmelo_inactive_users';
				case 'pay': return 'test_elmelo_pay_alpha_3';
				case 'user': return 'test_elmelo_users_1';

				case 'pub_info': return 'test_elmelo_beta_pub_info_1';

                default: return null;
                }   // // switch

            case 'live':
            default:
                switch( name )
                {
				case 'biz': return 'elmelo_biz_2'
				case 'geos': return 'elmelo_geos_2'
				case 'images': return 'elmelo_images_1';
				case 'menu': return 'elmelo_menu_1';
				case 'serv': return 'elmelo_serv_1';

				case 'order': return 'elmelo_order_3';


                case 'biz_info': return 'elmelo_rat_1';
				case 'pay_disc': return 'elmelo_pay_discount_1';
				case 'soc_event': return 'elmelo_event_0';
				case 'soc_cal': return 'elmelo_cal_0';
				case 'soc_rsrv': return 'elmelo_rsrv_0';
				case 'notify': return 'elmelo_notify_2';
				case 'wallet': return 'elmelo_wallet_3';

                case 'user:inactive': return 'elmelo_inactive_users';
                case 'pay': return 'elmelo_pay_alpha_3';
                case 'user': return 'elmelo_users_1';

                case 'pub_info': return 'elmelo_beta_pub_info_1';

                default: return null;
                }   // // switch
            }   // switch
		},
        chat: {
            bPush: true,
            bDb: true,
            bIoT: true,
        },

        iot: {
            platform: 'hs',
            prefix: 'a2gb0bhen4igg6-ats',
            policy: 'elml_chat',
        },
        aws: {
            roi: 'eu-west-1',
        },
        push: {
            platform: 'hs',
        },
    }
/**
 */
export default elml


