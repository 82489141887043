import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {navigate, Router,Redirect} from "@reach/router"
import Login from './components/auth/Login'
import P404 from './components/_common/404'
import AuthSignIn from "./components/auth/SignIn";
import AuthSignUp from "./components/auth/SignUp";
import AuthVerification from "./components/auth/Verification";
import Auth_Verification_Email from "./components/auth/VerificationEmail";
import AuthForgotten from "./components/auth/Forgotten";
import AuthLogout from "./components/auth/Logout";
import Settings from "./components/settings/home";
import React from "react";

import {ChatHome} from './components/chat';
import Home from './components/home/Home'

import {connect} from 'react-redux'
import * as actions from './rdx/actions'
import {Core} from './api/AWS'
import ChatIoT from './api/chat/chat_iot'
import Loader from 'react-loader-spinner'
import Hdr from "./components/_common/hdr";

/**
 */
class App extends React.PureComponent
{
    /**
     */
    constructor(props)
    {
        super(props)

        this.state = {
            idInt: -1,
            bInit: true
        }

        this._iot = null
    }

    /**
     */
    render()
    {
        if(this.state.bInit)
        {
            return (
                <div>
                    <Hdr/>
                    <div className="centerScreen">
                        <Loader
                            type="Bars"
                            color="#155799"
                            timeout={30000000}

                        />
                    </div>
                </div>
            )
        }
        return (
            <Router>
                <Home path='/' />
                <Login path='/auth'/>

                <Home path='/home'/>

                <AuthSignIn path='/auth/signin' />
                <AuthSignUp path='/auth/signup' />
                <AuthVerification path='/auth/verification' />
                <Auth_Verification_Email path='/auth/email/verification' />
                <AuthForgotten path='/auth/forgotten' />
                <AuthLogout path='/auth/logout' />
                <ChatHome path='/chat'/>
                <Settings path='/settings'/>
                <P404 default />

            </Router>

        )
    } // render

    /**
     */
    componentDidMount()
    {
        // console.log("called")
        // this.props.Rdx_Biz_Init()
        // this.props.Rdx_Core_Init()

        this.VerifyAuth()
            .then(data => {})
            .catch(err => {})

        this.NetworkStatus()
            .then(data => {})
            .catch(err => {})


        window.addEventListener('online', this.NetworkStatus_Wrapper)
        window.addEventListener('offline', this.NetworkStatus_Wrapper)
    }

    /**
     */
    RetrieveBiz = async () =>
    {
        try
        {
            const s_key = [this.props.__cfg.stage, 'settings', 'biz_info'].join( ':' )

            const aws_core = new Core({})
            const sel_biz = await aws_core.StorageGet(s_key)

            console.log("Selected biz:", sel_biz)

            this.props.Rdx_BizInfo( sel_biz )

        }
        catch( err )
        {
            console.warn( 'RetrieveBiz: err: ', err )

            return Promise.reject( err )
        }
    }   // RetrieveAlphas

    /**
     */
    componentWillUnmount()
    {
        window.removeEventListener('online', this.NetworkStatus_Wrapper)
        window.removeEventListener('offline', this.NetworkStatus_Wrapper)
    }

    /**
     */
    NetworkStatus_Wrapper = () =>
    {
        this.NetworkStatus()
            .then(data => {})
            .catch(err => {})
    }
    /**
     */
    NetworkStatus = async () =>
    {
        try {
            // // console.log( 'App.NetworkStatus: st' )

            const condition = navigator.onLine ? 'online' : 'offline'

            // // console.log( 'App.NetworkStatus: condition: ', condition )

            if (condition === 'online') {
                this.props.Rdx_Core_bNetwork(true)

                // this._idInt = setInterval( async () => {
                //   try
                //   {
                //     // // console.log( 'App.NetworkStatus: st' )

                //     await fetch( '//google.com', {node: 'no-cors',} )

                //     // console.log( 'App.NetworkStatus: Internet available' )

                //     this.props.Rdx_Core_bInternet( true )
                //   }
                //   catch( err )
                //   {
                //     console.warn( 'App.NetworkStatus: No Internet' )

                //     this.props.Rdx_Core_bInternet( false )
                //   }
                // }, 10000)   // 10 sec
            } else {
                console.warn('App.NetworkStatus: No Network')

                this.props.Rdx_Core_bNetwork(false)
                this.props.Rdx_Core_bInternet(false)

                if (-1 !== this._idInt) {
                    clearInterval(this._idInt)
                }
            }

            return {msg: 'OK'}
        } catch (err) {
            return Promise.reject(err)
        }
    }   // NetworkStatus
    /**
     */
    VerifyAuth = async () =>
    {
        try
        {

            const aws_core = new Core({});
            const current_cred = await aws_core.Credentials();

            this.setState({bInit: false})
            if(current_cred.authenticated)
            {
                const current_user = await aws_core.currentuser();

                if(current_user.attributes &&  ( current_user.attributes.email_verified ||  current_user.attributes.phone_number_verified  ) )
                {
                    this.props.RdxCore_AuthStatus( "auth" );
                    this.props.Rdx_BetaId( await aws_core.Id() );
                    this.props.Rdx_Set_Chat_Channel();
                    // this.props.Rdx_Pubid_Init();

                    // await this.RetrieveBiz();

                    // return navigate( '/home' )
                }
                else
                {
                    this.props.RdxCore_AuthStatus( "unauth" );

                    // if(current_user.attributes.hasOwnProperty('phone_number_verified'))
                    //     return navigate( '/auth/email/verification' )
                    // else
                    //     return navigate( '/auth/verification' )

                }
            }
            else
            {
                this.props.RdxCore_AuthStatus( "unauth" );
                return navigate("/auth/signin");
            }

            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'Login: VerifyAuthentication: err: ', err )
            this.setState( {err: err} )
        }
    }

} // class App

/**
 */
const mapStateToProps = (state) => {
    return state
}   //

/**
 */
export default connect(mapStateToProps, actions)(App)
