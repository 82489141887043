/**
 *  @copyright  Elmelo Ltd.
 */

import {Init, User_GetTotUnread, Channel_Markers, Msg_Last, Mark_Read} from '../../api/chat/chat_engine'
import { Core} from "../../api";
import ChatIoT from "../../api/chat/chat_iot";

/**
 */
export const Rdx_ChatInit_InProgress = () =>
{
    return {
        type: 'chat:init_inprogress'
    // ,   payload: selected
    };
}

/**
 */
export const Rdx_ChatInit_Complete = () =>
{
    return {
        type: 'chat:init_complete'
    // ,   payload: selected
    };
}

/**
 */
export const Rdx_Chat = ( chat_obj ) =>
{
    return {
            type: 'chat:elml'
        ,   payload: chat_obj
        }
}

/**
 */
export const Rdx_Set_Chat_Channel = ( chat_obj ) =>
{
    return {
            type: 'set:ch'
        ,   payload: chat_obj
        }
}
/**
 */
export const Rdx_Chat_Channel = ( obj ) =>
{
    return {
            type: 'chat:channel'
        ,   payload: obj
        }
}
//
export const Rdx_Pubid_Init = () =>
{
    return async (dispatch, gs) =>
    {
        try
        {
            const p_init = { usr: 'alpha', alpha: {biz_id: gs().__core.bizInfo.biz_id} };

            const resp_init = await Init( p_init, gs().__cfg );

            console.log("resp_init",resp_init)

            dispatch( Rdx_Chat_User_ID(resp_init.resp.usr_id ) );

            //
            return { msg: 'OK' }
        }
        catch( err )
        {
            console.warn( 'action/chat: Rdx_Pubid: err: ', err )
            return { err }
        }
    }   // return ...
}   // Rdx_Pubid
/**
 */
export const Rdx_Chat_User_ID = ( user_id ) =>
{
    return {
            type: 'chat:user-id'
        ,   payload: user_id
        }
}

/**
 */
export const Rdx_Chat_totUnRead_Upd = ( n_tot ) =>
{
    return {
            type: 'chat:tot_unread_upd'
        ,   payload: n_tot
        }
}   // Rdx_Chat_totUnRead_Upd

/**
 */
export const Rdx_Chat_totUnRead_ReqCnt = ( req_cnt ) =>
{
    return {
            type: 'chat:tot_unread_req_cnt'
        ,   payload: req_cnt
        }
}   // Rdx_Chat_totUnRead_ReqCnt

/**
 */
export const Rdx_Chat_totUnRead_ReqStatus = ( req_status ) =>
{
    return {
            type: 'chat:tot_unread_req_status'
        ,   payload: req_status
        }
}   // Rdx_Chat_totUnRead_ReqStatus

/**
 */
export const Rdx_Chat_totUnRead_Req = ( req_cnt ) =>
{
    return async ( dispatch, gs ) =>
    {
        try
        {
            if( 'in_progress' === gs().__chat.totUnRead_ReqStatus )
            {
                // // console.log( 'actions/chat: Rdx_Chat_totUnRead_Req: ReqStatus: ', 'in_progress: exiting' )

                dispatch( Rdx_Chat_totUnRead_ReqCnt( gs().__chat.totUnRead_ReqCnt+1 ) )

                return {msg: 'OK'}
            }

            dispatch( Rdx_Chat_totUnRead_ReqStatus('in_progress') )

            // // console.log( 'actions/chat: Rdx_Chat_totUnRead_Req: ReqStatus: ', 'in_progress' )

            do
            {
                dispatch( Rdx_Chat_totUnRead_ReqCnt( 0 ) )

                // // console.log( 'actions/chat: Rdx_Chat_totUnRead_Req: ReqCnt: ', 0 )

                // const tot_unread = await gs().__chat.sb.UnRead_Cnt_Ch()

                // // console.log( 'actions/chat: Rdx_Chat_totUnRead_Req: tot_unread: ', tot_unread )

                // dispatch( Rdx_Chat_totUnRead_Upd( await gs().__chat.sb.UnRead_Cnt_Ch() ) )
                const cnt_unread = await User_GetTotUnread({alpha: gs().__core.bizInfo.biz_id }, gs().__cfg )

                // // console.log( 'actions/chat: Rdx_Chat_totUnRead_Req: cnt_unread: ', cnt_unread )

                dispatch( await Rdx_Chat_totUnRead_Upd( cnt_unread ) )

                // // console.log( 'actions/chat: Rdx_Chat_totUnRead_Req: cnt updated' )
            }   while( gs().__chat.totUnRead_ReqCnt )

            dispatch( Rdx_Chat_totUnRead_ReqStatus('stale') )

            return {msg: 'OK'}
        }
        catch( err )
        {
            console.warn( 'actions/chat: Rdx_Chat_totUnRead_Req: err: ', err )

            return {err}
        }
    }   // return ...
}   // Rdx_Chat_totUnRead_Req

/**
 */
export const Rdx_Chat_Channel_List = ( chn_arr ) =>
{
    return {
        type: 'chat:channel'
        ,   payload: chn_arr
    }
}
/**
 */
export const Rdx_Chat_Channel_List_Update = ( chn_arr ) =>
{
    return {
        type: 'chat:channel:upd'
        ,   payload: chn_arr
    }
}
/**
 */
export const Rdx_Chat_Init = () =>
{
    return async ( dispatch, gs ) =>
    {
        try
        {
            if(gs().__chat.elml && typeof gs().__chat.elml.Connect === "function")
            {
                console.log("asd")
                return {}
            }

            await dispatch( Rdx_ChatInit_InProgress() )
            await dispatch( Rdx_Chat_User_ID( gs().__core.bizInfo.biz_id ) )


            const p_init = { usr: 'alpha', alpha: {biz_id: gs().__core.bizInfo.biz_id} }

            const resp_init = await Init( p_init, gs().__cfg )

            // console.log( 'actions/chat_core: Rdx_ChatInit: resp_init: ', resp_init )
            if( resp_init )
            {
                //
            }
            const p_iot = {
                beta_id: gs().__core.betaId,
                cfg: gs().__cfg,
                OnConn: () => dispatch( IoT_OnConn() ),
                OnClose: () => dispatch( IoT_OnClose() ),
            }

            const iot_obj = new ChatIoTEx( p_iot )

            iot_obj.RdxReady( dispatch, gs )

            const resp_init_iot = await iot_obj.Init( 'core' )

            // console.log( 'actions/chat_core: Rdx_ChatInit: resp_init_iot: ', resp_init_iot )

            if( resp_init_iot )
            {
                //
            }

            const resp_conn = await iot_obj.Connect('app')

            await iot_obj.Subscribe( 'chat', '__all__' )

            // await iot_obj.Subscribe( 'pos', '__all__' , gs().__core.bizInfo.biz_id )

            // console.log( 'actions/chat_core: Rdx_ChatInit: resp_conn: ', resp_conn )

            if( resp_conn )
            {
                //
            }

            dispatch( Rdx_Chat( iot_obj ) )
            dispatch( Rdx_Chat_totUnRead_Req() )

            await dispatch( Rdx_ChatInit_Complete() )

            return iot_obj
        }
        catch( err )
        {
            dispatch(Rdx_ChatInit_Complete())

            console.warn( 'actions/chat: Rdx_Chat_Init: err: ', err )

            return {err}
        }
    }   // return ...
}   // Rdx_Chat_totUnRead_Req
/**
 */
const IoT_OnConn = () =>
{
    return ( dispatch, gs ) =>
    {
        // console.log( 'actions/chat_core: IoT_OnConn: ', 'Connected' )

        dispatch( IoT_Status( true ) )

        return {}
    }
}
/**
 */
const IoT_OnClose = () =>
{
    return async ( dispatch, gs ) =>
    {
        try
        {
            // console.log( 'actions/chat_core: IoT_OnClose: ', 'Closed' )

            dispatch( IoT_Status( false ) )

            await dispatch(Rdx_Chat_Init())

            return {}
        }
        catch( err )
        {
            return { err }
        }
    }
}
/**
 */
const IoT_Status = ( iot_status ) =>
{
    return {
        type: 'chat:status',
        payload: iot_status,
    }
}
/**
 */
class ChatIoTEx extends ChatIoT
{
    /**
     */
    constructor( props )
    {
        super( props )

        this.gs = null;
        this.Dispatch = null;
    }   // cstr
    /**
     */
    RdxReady = ( dispatch, gs ) =>
    {
        this.Dispatch = dispatch
        this.gs = gs
    }   // Init
    /**
     */
    OnMsg = async ( topic, msg ) =>
    {
        try
        {
            console.log( 'actions/chat_core: OnMsg: obj: ', msg )
            console.log( 'actions/chat_core: OnMsg: obj: ', topic )

            const ch_id = msg.ch_id

            const pr_arr = [ this.Dispatch (Get_UnRead( ch_id ) ) , this.Dispatch(Rdx_Chat_totUnRead_Req()) ]

            const pr_resp = await Promise.all( pr_arr )

            console.log( "chat_home: OnMsg: pr_resp: ", pr_resp )

            this.Dispatch(Rdx_Chat_Channel_List_Update( {_nUnRead:
                    this.gs().__chat.ch && this.gs().__chat.ch.ch_id === msg.ch_id ? 0 :
                    pr_resp[0] , msg_obj: msg} ))

            if(this.gs().__chat.ch && this.gs().__chat.ch.ch_id === msg.ch_id)
            {
                this.Dispatch(Usr_Mark_Read(this.gs().__chat.ch.ch_id))
            }
            this.Dispatch(Rdx_Chat_totUnRead_Req())

            return {}
        }
        catch( err )
        {
            console.warn( 'actions/chat_core: OnMsg: err: ', err )

            return { err }
        }
    }   // OnMsg
    /**
     */
    OnEvent = async ( topic, obj ) =>
    {
        try
        {
            console.log( 'actions/chat_core: OnEvent: obj: ', obj )

        }
        catch( err )
        {
            console.warn( 'actions/chat_core: OnEvent: err: ', err )

            return { err }
        }
    }   // OnEvent

}   // ChatIoTEx
/**
 */
export const Get_UnRead = (ch_id) =>
{
    return async (dispatch, gs) => {

        try {

            const param = {
                ch_id: ch_id,
                alpha: {biz_id: gs().__core.bizInfo.biz_id},
            }

            // console.log( "chat_home: Get_UnRead: ch_id: ", ch_id )

            const ch_markers = await Channel_Markers(param, gs().__cfg)

            // console.log("chat_home: Get_UnRead: ch_markers: ", ch_markers)

            const mark_self = ch_markers.ch_usr.find(x => x.usr_id === gs().__core.bizInfo.biz_id)

            // console.log("chat_home: Get_UnRead: mark_self: ", mark_self)

            return mark_self ? mark_self._lag : 0

        } catch (err) {
            console.warn("Chat_ListItem: Get_UnRead: err: ", err)

            // return Promise.reject( err )
            return {err}
        }
    }
}   // Get_UnRead

/**
 */
export const Get_LastMsg = ( ch_id ) =>
{
    return async (dispatch, gs) => {

        try {
            const param = {
                ch_id: ch_id,
                alpha: {biz_id: gs().__core.bizInfo.biz_id}
            }
            const resp_msg_last = await Msg_Last(param, gs().__cfg)

            // console.log( "chat_home: Get_LastMsg: resp_msg_last: ", resp_msg_last )

            // this.setState( {msgLast: resp_msg_last} )
            return resp_msg_last

        } catch (err) {
            console.warn("actions/chat_core: Get_LastMsg: err: ", err)

            // return Promise.reject( err )
            return {err}
        }
    }
}   // Get_LastMsg

/**
 */
export const Usr_Mark_Read = ( ch_id ) =>
{
    return async (dispatch, gs) => {

        try
        {

            const p_ch = {
                usr_id: gs().__core.bizInfo.biz_id,
                ch_id:  ch_id,
                alpha: {biz_id:  gs().__core.bizInfo.biz_id}

            }

            await Mark_Read( p_ch, gs().__cfg )
            //
            return {}
        }
        catch( err )
        {
            console.warn( 'actions/chat_core: Mark_Read: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Get_LastMsg
/**
 */
export const Rdx_Chat_Clear = (val) =>
{
    return {
        type: 'chat:clear'
        ,   payload: val
    };
}
// /**
//  */
// export const Rdx_Chat_nUnRead = ( ch_url, n_unread ) =>
// {
//     return {
//             type: 'chat:n_unread'
//         ,   payload: {ch: ch_url, nUnRead: n_unread}
//         }
// }

// /**
//  */
// export const Rdx_Chat_MsgLast = ( ch_url, msg_last ) =>
// {
//     return {
//             type: 'chat:msg_last'
//         ,   payload: {ch: ch_url, msgLast: msg_last}
//         }
// }


